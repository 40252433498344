import LeftSideDetailComponent from '../LeftSideDetailComponent';
import styles from './index.module.scss';
import RightSideSignupComponent from './RightSideSignupComponent';

const NewSignUp = () => {
  return (
    <div className={styles.fwrkSignup}>
      <LeftSideDetailComponent />
      <RightSideSignupComponent />
    </div>
  );
};

export default NewSignUp;
