import React from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../../core/font';
import theme from '../../../../core/theme';
import { getText } from './getTexts';

const Intro = styled.div`
  width: 100%;
`

const Step = styled.h3`
  color: ${theme.primary};
  font-size: 20px;
`

const Title = styled.span`
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  padding: 0 15px;
`

const Bar = styled.div`
  background-color: ${theme.primary};
  height: 70px;
  width: 10px;
`

const Video = styled.video`
    border-radius: 10px;
    width: 90%;
`

const Description = styled.p`
  color: grey;
  font-size: 18;
`

const Question = styled.h4`
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  text-align: center;
  padding: 15px;
`

const Step1 = (props) => {
  return (
    <>
      <Intro>
        <Step>
          <strong>1/2 </strong> Overview
        </Step>
        <div style={{ display: 'flex', marginLeft: '-25px' }}>
          <Bar />
          <Title>{getText(props.groupName).pageTitle}</Title>
        </div>
        <Description>{getText(props.groupName).description}</Description>
      </Intro>
      <hr style={{
        alignSelf: 'center',
        backgroundColor: "grey",
        height: 1,
        marginVertical: 30,
        width: '100%',
      }} />
      <Question>{getText(props.groupName).videoTitle}</Question>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Video
          controls
          src={getText(props.groupName).uri}
        />
      </div>
    </>
  );
};

export default Step1;
