import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import * as font from '../../../core/font';
import { ReactComponent as AudioOnSVG } from '../../../assets/image/audio_on.svg';

export const ImageContainer = styled.div`
  width: fit-content;
  max-width: 1112px;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
`;

export const ButtonContainer = styled.div`
  width: 100vw;
  max-width: 1112px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  animation: fadeIn ease 1.5s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const AudioButton = styled.button`
  align-items: center;
  background-color: ${(props) => (props.active ? props.bgColor : `inherit`)};
  outline: none;
  border-radius: 50px;
  box-shadow: ${(props) =>
    `0 0 ${props.active ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
`;

export const AudioButtonText = styled.span`
  color: black;
  opacity: ${(props) => (props.active ? `100%` : `32%`)};
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  margin-left: 10px;
  margin-right: 25px;
`;

export const AudioOn = styled(AudioOnSVG)`
  position: absolute;
  top: -70px;
  left: 63px;
`;

export const VolumeContainer = styled.div`
  position: absolute;
  top: 50px;
  right: 0px;
  height: 90px;
  width: 150px;
  margin-top: 50px;
`;

// Styling sliders is not very well supported, so we have to do some hacky things here
export const VolumeControl = styled.input`
  outline: none;
  appearance: none;
  transform: rotate(-90deg);
  width: 150px;
  height: 30px;
  margin: 30px 0;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  z-index: 1;
  /* Chrome & Safari */
  ::-webkit-slider-runnable-track {
    /* Hides the slider so custom styles can be added */
    appearance: none;
    cursor: pointer;
    background: #e8e3d5;
    color: transparent;
    width: 150px;
    height: 3px;
    border: none;
    border-radius: 2.5px;
    overflow: hidden;
  }
  ::-webkit-slider-thumb {
    appearance: none;
    border: none;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: ${theme.primary};
    cursor: pointer;
    /* box-shadow gives us the fill color on the track below the thumb */
    box-shadow: -100vw 0 0 calc(100vw + 5px) ${theme.primary};
  }
  /* Firefox */
  ::-moz-range-track {
    appearance: none;
    cursor: pointer;
    background: #e8e3d5;
    color: transparent;
    width: 140px;
    height: 3px;
    border-radius: 2.5px;
    border: none;
    overflow: hidden;
  }
  ::-moz-range-thumb {
    appearance: none;
    border: none;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: ${theme.primary};
    cursor: pointer;
    /* box-shadow gives us the fill color on the track below the thumb */
    box-shadow: -100vw 0 0 calc(100vw + 5px) ${theme.primary};
  }
  /* IE */
  /* No need for box-shadow tricks, we have fill-lower for IE */
  ::-ms-fill-lower {
    background: ${theme.primary};
  }
  ::-ms-thumb {
    appearance: none;
  }
  ::-ms-ticks-after {
    display: none;
  }
  ::-ms-ticks-before {
    display: none;
  }
  ::-ms-track {
    appearance: none;
    cursor: pointer;
    background: #e8e3d5;
    width: 140px;
    height: 3px;
    border-radius: 2.5px;
    border: none;
  }
  ::-ms-tooltip {
    display: none;
  }
`;

// Lays over the default thumb so we can have it wider than the track
export const VolumeThumb = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: ${theme.primary};
  position: absolute;
  left: 67.5px;
  bottom: ${(props) => `${(props.volume - 22) * 1.4}px`};
  cursor: pointer;
  pointer-events: none;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  z-index: 3;
`;

// Blocks overflow of the box-shadow for firefox
export const MozBlock = styled.div`
  height: 151px;
  width: 15px;
  background-color: ${theme.background};
  position: absolute;
  top: -31px;
  ${(props) => (props.right ? `right` : `left`)}: 58.5px;
  pointer-events: none;
  z-index: 2;
`;
