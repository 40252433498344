import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input } from 'antd';
import { apiLoginAndAccountSetupStep } from 'api/auth/login';
import { emailValidator, passwordValidator } from 'core/utils';
import { useAuth } from 'providers/AuthContext';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'utils/toast';
import styles from './index.module.scss';

const RightSideSignupComponent = () => {
  const { Signup, setUserAuthToken } = useAuth();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [acceptedTermAndCondition, setAcceptedTermAndCondition] =
    useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const signUpUser = async () => {
    // validate for malformed or empty inputs
    const nameValidationError = passwordValidator(firstName);
    const emailValidationError = emailValidator(email);
    const passwordValidationError = passwordValidator(password);

    setFirstNameError(nameValidationError);
    setEmailError(emailValidationError);
    setPasswordError(passwordValidationError);

    if (
      nameValidationError ||
      emailValidationError ||
      passwordValidationError
    ) {
      return;
    }

    //signup execution
    try {
      setFirstNameError('');
      setEmailError('');
      setPasswordError('');
      setSignUpLoading(true);
      console.log(firstName, email, password);
      await Signup(
        email.toString().trim(),
        password.toString().trim(),
        firstName.toString().trim()
      );

      const loginAndAccountSetupStepRes = await apiLoginAndAccountSetupStep({
        email: email,
        password: password,
      });
      setUserAuthToken(loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
      if (loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken) {
        localStorage.setItem('framewrk_user_token', loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
      }
      navigate('/account-setup');
    } catch (e) {
      console.error(e);
      toast.error('Failed to create account');
    }

    setSignUpLoading(false);
  };

  return (
    <div className={styles.rightSideDetailComponent}>
      <div className={styles.signupContainer}>
        <div className={styles.signupInputContainer}>
          <div className={styles.signupInputTitle}>First Name</div>
          <div className={styles.signupInput}>
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e?.target?.value)}
              size="large"
              placeholder="Enter First Name"
              prefix={<UserOutlined />}
            />
          </div>
          <div>{firstNameError}</div>
        </div>

        <div className={styles.signupInputContainer}>
          <div className={styles.signupInputTitle}>Email</div>
          <div className={styles.signupInput}>
            <Input
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
              type="email"
              size="large"
              placeholder="Enter Email"
              prefix={<MailOutlined />}
            />
            <div>{emailError}</div>
          </div>
        </div>

        <div className={styles.signupInputContainer}>
          <div className={styles.signupInputTitle}>Password</div>
          <div className={styles.signupInput}>
            <Input
              value={password}
              onChange={(e) => setPassword(e?.target?.value)}
              type="password"
              size="large"
              placeholder="Enter Password"
              prefix={<LockOutlined />}
            />
            <div>{passwordError}</div>
          </div>
        </div>

        <div className={styles.termAndConditionContainer}>
          <div className="f-d-flex">
            <div className={styles.alreadyHaveAccountText}>
              Already have an account?
            </div>
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <div className={styles.signInLinkButton}>Sign In</div>
            </Link>
          </div>
          <div className="f-d-flex f-align-center f-py-8">
            <Checkbox
              onChange={(e) => setAcceptedTermAndCondition(e?.target?.checked)}
              checked={acceptedTermAndCondition}
            />
            <span className={styles.signInTermAndCondition}>
              I agree to all statements included in the
              <a
                href="https://www.youtube.com/@framewrk"
                target="none"
                className={styles.signInLinkButton}
              >
                {' '}
                Terms of Use
              </a>
            </span>
          </div>
        </div>

        <div className="f-d-flex f-justify-center f-py-24">
          <Button
            type="primary"
            size="large"
            className={styles.startAccountSetupButton}
            onClick={signUpUser}
            disabled={signUpLoading || !acceptedTermAndCondition}
            loading={signUpLoading}
          >
            <span className={styles.startAccountSetupButtonText}>
              Start Account Setup
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RightSideSignupComponent;
