import howItWorksImage from 'assets/image/hiw.svg';
import styles from './index.module.scss';

const LeftSideDetailComponent = () => {
  return (
    <div className={styles.leftSideDetailComponent}>
      <div className={styles.leftSideDetailComponentContainer}>
        <div className={styles.leftSideDetailAccountSetup}>
          <div className={styles.title}>Framewrk</div>
          <div className={styles.titleSecondary}>
            Complete your account and let's get started!
          </div>
          <div className={styles.youtubeVideoContainer}>
            <iframe
              title="Framewrk intro video"
              src="https://www.youtube.com/embed/87Y3vZjVJZg"
            ></iframe>
          </div>
          <div className="f-py-16">
            <div>
              <div className={styles.accountSetupTitle}>Account Setup Time</div>
              <div className={styles.accountSetupDetail}>1-2 Minutes</div>
            </div>
            <div>
              <div className={styles.accountSetupTitle}>
                Average Setup Feeling
              </div>
              <div className={styles.accountSetupDetail}>
                Don't Stop Believin' - Journey 1:22 timestamp
              </div>
            </div>
          </div>
        </div>

        <div className={styles.leftSideDetailHowItWorksContainer}>
          <div className="f-d-flex f-align-center">
            <div className={styles.howItWorksImageContainer}>
              <img src={howItWorksImage} alt={'how it works'} />
            </div>
            <div className={styles.howItWorksTitle}>How It Works</div>
          </div>
          <p className={styles.howItWorksDetail}>
            Being a Founder is unlike any other experience in life, and it's
            tough. Founders understand Founders, so Framewrk matches you with
            other great entrepreneurs in exclusive 3-person groups to meet
            weekly, support, and inspire each other. After you create your
            account, we'll start finding other Founders to introduce you to and
            kick off the weekly meetings. Think hard about your future success,
            because you're about to experience it. Below are more details:
          </p>
          <li className={styles.howItWorksListItem}>
            Weekly meetings and motivational reminders to keep going.
          </li>
          <li className={styles.howItWorksListItem}>
            Surprise gifts and experiences that aid the journey.
          </li>
          <li className={styles.howItWorksListItem}>
            $25/month pay-it-forward subscription.
          </li>
        </div>

        <div className={styles.howItWorksBottomContainer}>
          <div className={styles.contactContainer}>
            <div>FAQs</div>
            <a href="https://www.youtube.com/@framewrk" target="none">
              <span className="f-px-8">View</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.026"
                height="11.079"
                viewBox="0 0 7.026 11.079"
              >
                <path
                  id="Path_286"
                  data-name="Path 286"
                  d="M0,8.255l4.613-4.31L0,0"
                  transform="translate(1.413 1.41)"
                  fill="none"
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideDetailComponent;
