import React, { useEffect, useState, useContext } from 'react';
import * as RegionalSC from '../Components';
import * as GlobalSC from '../../../GlobalComponents';
import * as SC from './styles';
import theme from '../../../core/theme';
import { useNavigate } from 'react-router-dom';
import { Context } from '../../../providers/Context';

function EngagementLoading() {
  const [audioOn, setAudioOn] = useState(true);

  const { setVolume } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    const fakeAsync = setTimeout(() => {
      if (!audioOn) {
        setVolume(0);
      } else {
        setVolume(100);
      }
      navigate('/office-ready');
    }, 4000);
    return () => clearTimeout(fakeAsync);
  });

  return (
    <>
      <RegionalSC.Background />
      <RegionalSC.Container>
        <RegionalSC.SubContainer>
          <GlobalSC.H1>Your Engagement is Loading...</GlobalSC.H1>
          <GlobalSC.H2>
            We’re setting up your office! If you want the full experience, with
            audio and all, connect your headphones and select the ‘Audio On’
            option below.
          </GlobalSC.H2>
        </RegionalSC.SubContainer>
        <RegionalSC.SpinnerIcon />
        <div style={{ marginBottom: '150px' }}>
          <SC.AudioButton
            bgColor={theme.secondary}
            active={audioOn}
            onClick={() => setAudioOn(true)}
          >
            <RegionalSC.AudioOnIcon active={audioOn} />
            <SC.AudioButtonText active={audioOn}>Audio On</SC.AudioButtonText>
          </SC.AudioButton>
          <SC.AudioButton
            bgColor={theme.secondary}
            active={!audioOn}
            onClick={() => setAudioOn(false)}
          >
            <RegionalSC.AudioOffIcon active={!audioOn} />
            <SC.AudioButtonText active={!audioOn}>Audio Off</SC.AudioButtonText>
          </SC.AudioButton>
        </div>
        <GlobalSC.InfoPopUp>
          <GlobalSC.InfoPopUpHeader>Why Audio On?</GlobalSC.InfoPopUpHeader>
          <GlobalSC.InfoPopUpBody>
            You’ll hear custom sounds that we created to help your brain get
            more creative + productive as you work in the app. Click
            <GlobalSC.Highlight>
              {' '}
              ‘The Science Behind the Sounds’{' '}
            </GlobalSC.Highlight>
            to learn more.
          </GlobalSC.InfoPopUpBody>
        </GlobalSC.InfoPopUp>
      </RegionalSC.Container>
    </>
  );
}

export default EngagementLoading;
