import { Alert, Box, Checkbox, Container, Snackbar, Stack } from "@mui/material";
import { Button } from 'antd';
import { useAuth } from "providers/AuthContext";
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useParams
} from "react-router-dom";
import { getStripe } from "../../core/utils";
import { Title } from "../../GlobalComponents";
import axios from "../../utils/axiosClient";
import * as events from "../../utils/events";
import { EasterEgg } from "../Auth/Components";
import { Subtitle } from './styles';

const Guest = () => {
  const { currentUser, userAuthToken } = useAuth()
  const navigate = useNavigate()


  let { email } = useParams();
  const [product, setProduct] = useState()
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [priceIsLoading, setPriceIsLoading] = useState(false);
  const [currentPrice, setCurrentPrice] = useState();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, setError] = React.useState('');

  useEffect(() => {
    window.gtag('event', events.GUEST_ACCOUNT_SCREEN_VIEW, {
      email
    });
  }, [email])

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/stripe/products');
      const data = response.data[0]
      setProduct(data)
      setCurrentPrice(data.defaultPrice)

      try {
        const { data } = await axios.get('/public/user', { params: { email } });
        setUserName(data.name)
        setUserId(data.userId)
      } catch (err) {
        console.log('User Not found')
      }
    }
    fetchData()
  }, [email])

  const redirectPayment = async (priceId) => {
    try {
      const response = await axios.post('/stripe/create-guest-checkout-session', { price: { id: priceId }, quantity: 1, userId });
      if (response.data.sessionId) {
        window.gtag('event', events.GUEST_ACCOUNT_REDIRECT_TO_STRIPE, {
          userId,
          priceId
        });
        const stripe = await getStripe();
        await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
      }
    } catch (error) {
      setError(error.response?.data?.error?.message || '')
      setOpenAlert(true)
    }
  }

  const handleCheckout = async (priceId) => {
    window.gtag('event', events.GUEST_ACCOUNT_CHECKOUT_PRESSED, {
      userId,
      priceId
    });
    if (!priceId) {
      return;
    }

    setPriceIsLoading(true);

    try {
      await redirectPayment(priceId)
    } catch (error) {
      return alert(error.message);
    } finally {
      setPriceIsLoading(false);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setError('')
  };
  if (currentUser && userAuthToken) {
    navigate('/account')
  }

  return (
    <div style={{ backgroundColor: '#E1F2E9', height: "100vh", display: 'flex' }}>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
      <Container>
        {userName && (
          <Stack direction='row' spacing={2} style={{ paddingTop: 70 }}>
            <EasterEgg />
            <Title>{userName}</Title>
          </Stack>
        )}
        <Stack direction='row' spacing={2} style={{ paddingTop: userName ? 0 : 70 }}>
          <EasterEgg />
          <Title>It's time to subscribe!</Title>
        </Stack>
        <Stack alignItems="center" style={{ marginTop: 20, marginBottom: 40 }}>
          <Subtitle>Choose an option below.</Subtitle>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6} justifyContent='center'>
          {product?.prices.map(price => {
            const priceString = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: price.currency,
              minimumFractionDigits: 0,
            }).format(price.unitAmount / 100);
            return <Stack key={price.id} direction='column' alignItems="center" >
              <Box
                key={price.id}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '10px',
                  width: 300,
                  height: 300,
                  backgroundColor: '#FFFFFF',
                  opacity: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Subtitle style={{ marginTop: 20 }}>{price.metadata.title}</Subtitle>
                <Stack direction='column' alignItems="center" justifyContent='center' style={{
                  padding: 10,
                  margin: 10,
                  backgroundColor: '#4EBA9A',
                  borderRadius: "50%",
                  width: 130,
                  height: 130,
                }} >
                  <div style={{ color: `#FFFFFF`, fontFamily: 'WorkSans-Medium', fontSize: 20, fontWeight: 'bold' }}>{priceString}</div>
                  <div style={{ margin: 5, fontWeight: 'bold' }}>Billed {price.interval === 'month' ? 'Monthly' : 'Yearly'}</div>
                </Stack>
                <div style={{ color: `#000000`, fontSize: 13, fontWeight: 'bold', textAlign: 'center', margin: 10 }}>{price.description}</div>
                <Checkbox checked={currentPrice === price.id} onChange={() => {
                  window.gtag('event', events.GUEST_ACCOUNT_PICK_PRICE, {
                    userId,
                    priceId: price.id
                  });
                  setCurrentPrice(price.id)
                }} />
              </Box>

            </Stack>
          })}
        </Stack>
        <Stack alignItems="center" style={{ margin: 20 }}>
          <Button
            type="primary"
            size="large"
            style={{
              marginTop: '10px',
              height: 'auto'
            }}
            disabled={priceIsLoading}
            loading={priceIsLoading}
            onClick={() => handleCheckout(currentPrice)}
          >
            <span style={{
              color: '#4a4a4a',
              fontSize: '20px',
              fontWeight: '700',
              padding: '6px 10px'
            }}>
              Subscribe
            </span>
          </Button>
        </Stack>
      </Container >
    </div >
  );
};

export default Guest;
