import { CaretLeftFilled } from '@ant-design/icons';
import { Progress, Spin } from "antd";
import { apiUserSurveryFilledData } from 'api/account-setup/userSurveryFilledData';
import FramewrkLogo from "assets/image/FramewrkLogo.png";
import { AccountSetupBackIcon } from "icons";
import { IFormStep } from "interface";
import { AppContext } from 'providers/app-context';
import { useAuth } from 'providers/AuthContext';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { AccountSetupForms } from "utils/constants";
import CompanyIndustryForm from "./AccountSetupForms/CompanyIndustryForm";
import CompanyStageForm from "./AccountSetupForms/CompanyStageForm";
import CongratulationFormComplete from "./AccountSetupForms/CongratulationFormComplete";
import FounderGenderForm from "./AccountSetupForms/FounderGenderForm";
import FounderRaceForm from "./AccountSetupForms/FounderRaceForm";
import MailingAddressForm from "./AccountSetupForms/MailingAddressForm";
import styles from "./index.module.scss";
import LeftSideBarAccountSetupProgressMenuComponent from "./LeftSideBarComponents/LeftSideBarAccountSetupProgressMenuComponent";
import RightSideBarIndexComponent from "./RightSideBarComponents/RightSideBarIndexComponent";
import RightSideBarIndustryExampleComponent from "./RightSideBarComponents/RightSideBarIndustryExampleComponent";
import RightSideBarInviteComponent from "./RightSideBarComponents/RightSideBarInviteComponent";
import RightSideBarMailingExampleComponent from "./RightSideBarComponents/RightSideBarMailingExampleComponent";

const AccountSetup = () => {
  const { userAuthToken } = useAuth();
  const { setCompanyStage, setCompanyIndustry, setFounderGender, setFounderRace } = useContext(AppContext);
  const navigate = useNavigate();
  const { Logout } = useAuth();
  const [currentStep, setCurrentStep] = useState<IFormStep>(AccountSetupForms.STEP1);
  const [formCompletePercentage, setFormCompletePercentage] = useState(0);
  const [surveryLoading, setSurveryLoading] = useState(false);

  const navigateToFormStep = (currentForm: IFormStep, direction: "next" | "prev") => {
    if (direction === "next" && currentForm?.nextStep) {
      const formValueArrary = Object.values(AccountSetupForms);
      const nextStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.nextStep);
      setCurrentStep(nextStep);
    } else if (direction === "prev" && currentForm?.prevStep) {
      const formValueArrary = Object.values(AccountSetupForms);
      const prevStep = formValueArrary.find((value: IFormStep) => value?.index === currentForm?.prevStep);
      setCurrentStep(prevStep);
    }
  };

  const getAndLoadSurveryQuestionData = async () => {
    setSurveryLoading(true);
    const userSurveryFilledQuestion = await apiUserSurveryFilledData(userAuthToken);
    if (userSurveryFilledQuestion?.data?.isComplete) {
      navigate('/account');
      // navigate('/choose-your-office');
    } else {
      const surverySteps = userSurveryFilledQuestion?.data?.step;
      setCompanyStage(surverySteps?.CompanyStage);
      setCompanyIndustry(surverySteps?.CompanyIndustry);
      setFounderGender(surverySteps?.Gender);
      setFounderRace(surverySteps?.FounderRace);

      if (surverySteps?.CompanyStage === undefined) {
        setCurrentStep(AccountSetupForms.STEP1);
        setFormCompletePercentage(20);
      } else if (surverySteps?.CompanyIndustry === undefined) {
        setCurrentStep(AccountSetupForms.STEP2);
        setFormCompletePercentage(40);
      } else if (surverySteps?.Gender === undefined) {
        setCurrentStep(AccountSetupForms.STEP3);
        setFormCompletePercentage(60);
      } else if (surverySteps?.FounderRace === undefined) {
        setCurrentStep(AccountSetupForms.STEP4);
        setFormCompletePercentage(80);
      } else {
        setCurrentStep(AccountSetupForms.STEP5);
        setFormCompletePercentage(100);
      }
    }
    setSurveryLoading(false);
  }

  useEffect(() => {
    getAndLoadSurveryQuestionData();
  }, []);

  return (
    <div className={styles.accountSetup}>
      {/* Left side bar component */}
      <div className={`${styles.accountSetupSidebarCommon} ${styles.accountSetupLeftSideBar}`}>
        <div
          className={`${styles.accountSetupBackButtonContainer}`}
        >
          <div onClick={async () => {
            await Logout()
            navigate('/login')
          }}>
            <AccountSetupBackIcon style={{ fill: "#ffffff" }} />
          </div>
          <div onClick={() => navigateToFormStep(currentStep, "prev")} className={`${currentStep?.index === "01" && styles.disabled}`}>
            <CaretLeftFilled style={{ color: 'white', fontSize: '20px' }} />
          </div>
        </div>

        <LeftSideBarAccountSetupProgressMenuComponent currentForm={currentStep} />
      </div>

      {/* Main center component */}
      <div className={styles.accountSetupMainSection}>
        {surveryLoading ? <>
          <div className={styles.accountSetupMainSectionLoaderContainer}>
            <Spin size='large'></Spin>
          </div>
        </> : <>

          {/* STEP1 */}
          {currentStep?.index === "01" && (
            <CompanyStageForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} updateCompletePercentage={setFormCompletePercentage} />
          )}

          {/* STEP2 */}
          {currentStep?.index === "02" && (
            <CompanyIndustryForm
              currentForm={currentStep}
              navigateToFormStep={navigateToFormStep}
              updateCompletePercentage={setFormCompletePercentage}
            />
          )}

          {/* STEP3 */}
          {currentStep?.index === "03" && (
            <FounderGenderForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} updateCompletePercentage={setFormCompletePercentage} />
          )}

          {/* STEP4 */}
          {currentStep?.index === "04" && (
            <FounderRaceForm currentForm={currentStep} navigateToFormStep={navigateToFormStep} updateCompletePercentage={setFormCompletePercentage} />
          )}

          {/* STEP5 */}
          {currentStep?.index === "05" && (
            <MailingAddressForm
              currentForm={currentStep}
              navigateToFormStep={navigateToFormStep}
              updateCompletePercentage={setFormCompletePercentage}
            />
          )}

          {/* STEP6 */}
          {currentStep?.index === "06" && <CongratulationFormComplete currentForm={currentStep} updateCompletePercentage={setFormCompletePercentage} />}
        </>}
      </div>

      {/* Right side bar */}
      <div className={`${styles.accountSetupSidebarCommon} ${styles.accountSetupRightSideBar}`}>
        <div className={styles.accountSetupFramewrkLogoContainer}>
          <img src={FramewrkLogo} alt="Framewrk Logo" />
        </div>

        <div className={styles.setupProgressContainer}>
          <div className={styles.setupProgressTitle}>Status</div>

          <Progress
            className={styles.setupProgressPercentageText}
            type="circle"
            percent={formCompletePercentage}
            trailColor="transparent"
            strokeColor="#4EBA9A"
            strokeWidth={23}
          />
        </div>

        <div className={styles.sectionDevider}>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </div>

        <div className={styles.rightSideBarBottomContainer}>
          {currentStep?.index === "01" && <RightSideBarIndexComponent />}
          {currentStep?.index === "02" && <RightSideBarIndustryExampleComponent />}
          {currentStep?.index === "05" && <RightSideBarMailingExampleComponent />}
          {currentStep?.index === "06" && <RightSideBarInviteComponent />}
        </div>
      </div>
    </div>
  );
};

export default AccountSetup;
