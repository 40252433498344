import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as SC from './styles';
import * as RegionalSC from '../Components';
import * as GlobalSC from '../../../GlobalComponents';
import theme from '../../../core/theme';
import { useAuth } from '../../../providers/AuthContext';
import { useMyContext } from '../../../providers/Context';

import videoUrls from '../../../assets/videoURLS.json';

const arrow = require('../../../assets/image/arrow_next.png');

function ChooseOffice() {
  const navigate = useNavigate();
  const { volume, setVolume } = useMyContext();

  const [notificationOpen, setNotificationOpen] = useState(false);

  const officeSelectedArray = new Array(videoUrls?.officeBackgrounds?.length).fill(false);
  const [officeSelected, setOfficeSelected] = useState(officeSelectedArray);

  const [audioOn, setAudioOn] = useState(true);

  function handleVolumeChange({ target }) {
    setVolume(target.value);
  }

  function selectOffice(i) {
    const newOfficeSelectedArray = new Array(videoUrls?.officeBackgrounds?.length).fill(
      false
    );
    newOfficeSelectedArray[i] = true;
    setOfficeSelected(newOfficeSelectedArray);
    setNotificationOpen(true);
  }

  function turnAudioOn() {
    setVolume(100);
    setAudioOn(true);
  }

  function turnAudioOff() {
    setVolume(0);
    setAudioOn(false);
  }

  const { currentUser } = useAuth();

  function nextPage() {
    navigate('/office-ready');
  }

  return (
    <>
      <RegionalSC.Background />
      <RegionalSC.Container>
        {officeSelected.some((i) => i) ? (
          <SC.VolumeContainer>
            <SC.AudioOn />
            <SC.VolumeControl
              type="range"
              value={volume}
              onChange={handleVolumeChange}
            />
            <SC.VolumeThumb volume={volume} />
            <SC.MozBlock left />
            <SC.MozBlock right />
          </SC.VolumeContainer>
        ) : null}
        <div>
          <GlobalSC.H1>Welcome Back, {currentUser.displayName}!</GlobalSC.H1>
          <GlobalSC.H2 style={{ textAlign: 'center' }}>
            Click where you would like to work today!
          </GlobalSC.H2>
          <SC.ImageContainer>
            {videoUrls?.officeBackgrounds?.map((office, i) => {
              return (
                <RegionalSC.OfficeImage
                  text={office.text}
                  videoSource={office.video}
                  posterSource={office.still}
                  source={office.thumbnail}
                  audioSource={office.audio}
                  selected={officeSelected[i]}
                  selectOffice={() => selectOffice(i)}
                  key={i}
                />
              );
            })}
          </SC.ImageContainer>
        </div>
        {officeSelected.some((i) => i) ? (
          <SC.ButtonContainer>
            <div style={{ width: '90px' }}></div>
            <div>
              <SC.AudioButton
                bgColor={theme.secondary}
                active={audioOn}
                onClick={turnAudioOn}
              >
                <RegionalSC.AudioOnIcon active={audioOn} />
                <SC.AudioButtonText active={audioOn}>
                  Audio On
                </SC.AudioButtonText>
              </SC.AudioButton>
              <SC.AudioButton
                bgColor={theme.secondary}
                active={!audioOn}
                onClick={turnAudioOff}
              >
                <RegionalSC.AudioOffIcon active={!audioOn} />
                <SC.AudioButtonText active={!audioOn}>
                  Audio Off
                </SC.AudioButtonText>
              </SC.AudioButton>
            </div>
            <GlobalSC.NextButton
              active={true}
              bgColor={theme.secondary}
              onClick={nextPage}
            >
              <img src={arrow} alt="next btn" width={30} />
            </GlobalSC.NextButton>
          </SC.ButtonContainer>
        ) : null}
      </RegionalSC.Container>
      <GlobalSC.NotificationPopUp
        title="Why Audio On?"
        open={notificationOpen}
        close={() => setNotificationOpen(false)}
        body={`
        You’ll hear custom sounds that we created to help your brain get
        more creative + productive as you work in the app. Click
        <a href="http://testframewrk.com/" style="color: ${theme.primary}">‘The Science Behind the Sounds’</a>
        to learn more.
        </>`}
      />
    </>
  );
}

export default ChooseOffice;
