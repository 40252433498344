import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import TopNav from './Components/TopNav';
import Homebase from './Homebase';
import { Context } from '../../providers/Context';
import { Outlet } from "react-router-dom";
import notificationData from '../../core/notification.json'
import { NotificationPopUp } from '../../GlobalComponents';
import theme from '../../core/theme';

const Container = styled.div`
  overflow-x: hidden;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;


const Office = () => {

  const { notification, closeNotification, addNotification, openNotification, notificationOpen } = useContext(Context);

  useEffect(() => {
    notificationData.body += `<span style='color: ${theme.primary}'>Sent at : ${notificationData.timestamp} </span >`
    addNotification(notificationData)
    openNotification();
  }, [])

  return (
    <Container>
      <Outlet />
      <TopNav />
      <Homebase />
      <NotificationPopUp
        close={closeNotification}
        open={notificationOpen}
        {...notification}
      />
    </Container>
  );
};

export default Office;
