import IconButton from '@mui/material/IconButton';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import mapIcon from '../../../assets/image/mapIcon.svg';
import messageIcon from '../../../assets/image/messageIcon.svg';
import { Context } from '../../../providers/Context';

const SwitchContainer = styled.div`
    display: flex;
    
`;

const HoverContainer = styled.div`
    width:28px;
    height:28px;
    margin:1px;

    &:hover{
        background-color:white;
        cursor:pointer;
        transition-delay:.08s;
        border-radius:50px;
    }
    background-color:${props => props.selected ? "white" : "#4EBA9A"};

    border-radius:50px;

`

const Icon = styled.img`
    width:28px;
    height:28px;
    padding:4px;
`;

const ToggleContainer = styled.div`
    padding:1px;
    margin:5px;
    width: 66px;
    height: 35px;
    background-color: #4EBA9A;
    box-shadow: -2px 1px 2px 1px rgba(0,0,0,.25);
    border-radius:40px;

`


function Switch() {
    const navigate = useNavigate();

    let {
        homeBaseToggle,
        setHomeBaseToggle,
        roadmapPath
    } = useContext(Context);

    const handleToggleHomeBase = () => {
        navigate("/office")
        setHomeBaseToggle(false)
    }

    const handleToggleOther = () => {
        navigate(roadmapPath)
        setHomeBaseToggle(true)

    }


    return (
        <SwitchContainer>
            <ToggleContainer>
                <IconButton size="small" style={{ padding: "1px" }} onClick={handleToggleHomeBase}>
                    <HoverContainer selected={homeBaseToggle === false}>
                        <Icon src={messageIcon} />

                    </HoverContainer>

                </IconButton>
                <IconButton size="small" style={{ padding: "1px" }} onClick={handleToggleOther}>
                    <HoverContainer selected={homeBaseToggle === true}>
                        <Icon src={mapIcon} />
                    </HoverContainer>

                </IconButton>
            </ToggleContainer>
        </SwitchContainer>

    )
}

export default Switch
