import React from 'react';
import * as SC from './LeftContainerStyles';
import { ReactComponent as Logo } from '../../assets/image/logo.svg';

const LeftContainer = (props) => (
  <SC.LeftContainer>
    <SC.LogoContainer>
      <Logo />
    </SC.LogoContainer>
    {props.children}
    <SC.VerticalLine />
    <SC.TestmonialContainer>
      <SC.Testimonial>
        "If you do not know how to ask the right question, you discover
        nothing."
      </SC.Testimonial>
      <SC.NameContainer>
        <SC.Name>W. Edwards Deming</SC.Name>
        <SC.NameUnderline />
      </SC.NameContainer>
    </SC.TestmonialContainer>
  </SC.LeftContainer>
);

export default LeftContainer;
