import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import * as font from '../../../core/font';

export const AudioButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.active ? props.bgColor: `inherit`};
  outline: none;
  border-radius: 50px;
  box-shadow: ${(props) =>
    `0 0 ${props.active ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
`;

export const AudioButtonText = styled.span`
  color: black;
  opacity: ${(props) => props.active ? `100%` : `32%`};
  font-family: ${font.WorkSansSemiBold};
  font-size: 22px;
  margin-left: 10px;
  margin-right: 25px;
`;