import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Input, message } from 'antd';
import { apiUserSurveryFilledData } from 'api/account-setup/userSurveryFilledData';
import { apiLoginAndAccountSetupStep } from 'api/auth/login';
import { emailValidator, passwordValidator } from 'core/utils';
import { AppContext } from "providers/app-context";
import { useAuth } from 'providers/AuthContext';
import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'utils/toast';
import styles from './index.module.scss';

const RightSideLoginComponent = () => {
  const { setCompanyStage, setCompanyIndustry, setFounderGender, setFounderRace } = useContext(AppContext);
  const { Login, setUserAuthToken, recover } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginLoading, setLoginLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const loginUser = async () => {
    const emailValidationError = emailValidator(email);
    const passwordValidationError = passwordValidator(password);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }
    //executes login
    try {
      setEmailError('');
      setPasswordError('');
      setLoginLoading(true);
      let res = await Login(email, password);
      console.log('Login Res : ', res);
      const loginAndAccountSetupStepRes = await apiLoginAndAccountSetupStep({
        email: email,
        password: password,
      });
      setUserAuthToken(loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
      if (loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken) {
        localStorage.setItem('framewrk_user_token', loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
        const userSurveryFilledQuestion = await apiUserSurveryFilledData(loginAndAccountSetupStepRes?.user?.stsTokenManager?.accessToken);
        if (userSurveryFilledQuestion?.data?.isComplete) {
          navigate('/account');
          // navigate('/choose-your-office');
        } else {
          const surverySteps = userSurveryFilledQuestion?.data?.step;
          setCompanyStage(surverySteps?.CompanyStage);
          setCompanyIndustry(surverySteps?.CompanyIndustry);
          setFounderGender(surverySteps?.Gender);
          setFounderRace(surverySteps?.FounderRace);
          navigate('/account-setup');
        }
      }

    } catch {
      console.log('Failed to sign in');
      toast.error('Failed to sign in');
      setLoginLoading(false);
    }
  };

  const resetEmail = async () => {
    const emailValidationError = emailValidator(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
    try {
      setEmailError('');
      setLoginLoading(true);
      await recover(email)
      messageApi.open({
        type: 'success',
        content: 'Email sent',
      });
    } catch (error) {
      messageApi.open({
        type: 'error',
        content: 'There was an error',
      });
    } finally {
      setLoginLoading(false);
    }
  }

  return (
    <div className={styles.rightSideDetailComponent}>
      {!resetPassword ? (
        <div className={styles.loginContainer}>
          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Email</div>
            <div className={styles.loginInput}>
              <Input
                type="email"
                size="large"
                placeholder="Enter Email"
                prefix={<MailOutlined />}
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    loginUser()
                  }
                }}
              />
              <div>{emailError}</div>
            </div>
          </div>

          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Password</div>
            <div className={styles.loginInput}>
              <Input
                type="password"
                size="large"
                placeholder="Enter Password"
                prefix={<LockOutlined />}
                value={password}
                onChange={(e) => setPassword(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    loginUser()
                  }
                }}
              />
              <div>{passwordError}</div>
            </div>
          </div>

          <div className={styles.termAndConditionContainer}>
            <div className="f-d-flex">
              <div className={styles.alreadyHaveAccountText}>
                Don't have a account?
              </div>
              <Link to="/signup" style={{ textDecoration: 'none' }}>
                <div className={styles.signUnLinkButton}>Sign Up</div>
              </Link>
            </div>
          </div>

          <div className={styles.forgot}>
            <div className="f-d-flex f-align-center">
              <div className={styles.alreadyHaveAccountText}>
                Forgot / Reset Password
              </div>
              <Button type='link' onClick={() => setResetPassword(true)} className={styles.signUnLinkButton}>
                Reset
              </Button>
            </div>
          </div>

          <div className="f-d-flex f-justify-center f-py-24">
            <Button
              type="primary"
              size="large"
              className={styles.startAccountSetupButton}
              disabled={loginLoading}
              loading={loginLoading}
              onClick={loginUser}
            >
              <span className={styles.startAccountSetupButtonText}>
                Start Account Setup
              </span>
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.loginContainer}>
          {contextHolder}
          <div className={styles.loginInputContainer}>
            <div className={styles.loginInputTitle}>Email</div>
            <div className={styles.loginInput}>
              <Input
                type="email"
                size="large"
                placeholder="Enter Email"
                prefix={<MailOutlined />}
                value={email}
                onChange={(e) => setEmail(e?.target?.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    resetEmail()
                  }
                }}
              />
              <div>{emailError}</div>
            </div>
          </div>

          <div className={styles.forgot}>
            <div className="f-d-flex f-align-center">
              <Button type='link' onClick={() => setResetPassword(false)} className={styles.signUnLinkButton}>
                Go back
              </Button>
            </div>
          </div>

          <div className="f-d-flex f-justify-center f-py-24">
            <Button
              type="primary"
              size="large"
              className={styles.startAccountSetupButton}
              disabled={loginLoading}
              loading={loginLoading}
              onClick={resetEmail}
            >
              <span className={styles.startAccountSetupButtonText}>
                Send recovery email
              </span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSideLoginComponent;
