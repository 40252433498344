import React from "react";
import styled from "styled-components/macro";
import * as font from "../../../core/font";

  const IncomingMsg = styled.div`
    width: 380px;
    justify-content: center;
    margin-left: 10px;
    padding: 5px 5px 5px 15px;
    color: black;
    font-family: ${font.WorkSansSemiBold};
    font-weight: 14px;
    border-radius: 10px;
  `;
export default function IncomingMessage({ children }) {
  return <IncomingMsg>{children}</IncomingMsg>;
}
