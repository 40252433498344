import { useContext } from 'react';
import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import { Context } from '../../../providers/Context';
import OnlineIndicator from './OnlineIndicator';

const HomebaseOperations = styled.div`
  height: 60px;
  padding-left: 30px;
  margin-bottom: 5px;
  display: flex;
  &:hover {
    transition-delay: 0.08s;
    background-color: ${theme.background};
    cursor: pointer;
  }
`;

function Operations() {
  const { setMessengerState } = useContext(Context);

  function showMessengerPane() {
    setMessengerState({
      id: 1,
      display: "operations",
      name: 'Framewrk',
      title: 'Consultant',
      chatType: '1v1Chat',
      group: false,
      roomColor: '#4EBA9A',
      users: [{ Username: 'Framewrk', uID: '1' }],
      online: true,
    });
  }
  return (
    <HomebaseOperations onClick={showMessengerPane}>
      <OnlineIndicator online={true} />
      <div>
        <h2>Operations Q+A</h2>
        <span>Let's get to work</span>
      </div>
    </HomebaseOperations>
  );
}

export default Operations;
