import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/Fonts/CircularTT-Medium.ttf';
import './assets/Fonts/WorkSans-Medium.ttf';
import './assets/Fonts/WorkSans-SemiBold.ttf';
import './global.scss';
import './index.css';

import AuthProvider from './providers/AuthContext';
import ContextProvider from './providers/Context';

const root = ReactDOM.createRoot(document.getElementById('root'));
const localStorageToken = localStorage.getItem("framewrk_user_token");

root.render(
    <BrowserRouter>
        <AuthProvider localStorageToken={localStorageToken}>
            <ContextProvider>
                <App />
            </ContextProvider>
        </AuthProvider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
