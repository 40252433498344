import { Navigate, Outlet, useSearchParams } from 'react-router-dom';
import { useAuth } from '../providers/AuthContext';

function PrivateRoute() {
  const { currentUser, userAuthToken } = useAuth()
  let [search] = useSearchParams();
  const stripeInfo = search.get('stripeInfo')
  const to = stripeInfo ? `/login?stripeInfo=${stripeInfo}` : '/login'
  return (currentUser && userAuthToken) ? <Outlet /> : <Navigate to={to} />;
}

export default PrivateRoute;
