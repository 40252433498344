import { Button, Checkbox, Col, Row } from "antd";
import { apiCompanyStage } from "api/account-setup/companyStage";
import { IFormStep } from "interface";
import { AppContext } from "providers/app-context";
import { useAuth } from "providers/AuthContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const CompanyStageForm = ({ currentForm, navigateToFormStep, updateCompletePercentage }: IProps) => {
  const { companyStage, setCompanyStage } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyStageUpdateLoading, setCompanyStageUpdateLoading] = useState(false);

  useEffect(() => {
    updateCompletePercentage(0);
  }, []);

  const companyStageChanged = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setCompanyStage(updatedValue);
  };

  const updateCompanyStage = async () => {
    try {
      setCompanyStageUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        stages: companyStage,
      };
      await apiCompanyStage(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyStageUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>{currentForm?.index}</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.formTypeText}>Checkbox</div>
        <div className={styles.formQuestionText}>What stage is your idea or company at?</div>
        <div className={styles.formQuestionDetail}>
          Please select one stage. If you don't know what a particular stage means, check the index on the right side of the page.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`}>
          <Checkbox.Group style={{ width: "100%" }} onChange={companyStageChanged} value={[companyStage]}>
            <Row>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>1</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Ideation</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Ideation" autoFocus={true}></Checkbox>
              </Col>

              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>2</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Validation</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Validation"></Checkbox>
              </Col>

              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>3</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Operational</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Operational"></Checkbox>
              </Col>

              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>4</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Growth</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Growth"></Checkbox>
              </Col>

              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>5</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Establishing</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Establishing"></Checkbox>
              </Col>

              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>6</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Exiting</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Exiting"></Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateCompanyStage}
            loading={companyStageUpdateLoading}
            disabled={companyStageUpdateLoading || !companyStage}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next Section</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyStageForm;
