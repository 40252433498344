import { Button, Input } from "antd";
import { apiCompanyIndustry } from "api/account-setup/companyIndustry";
import { IFormStep } from "interface";
import { AppContext } from "providers/app-context";
import { useAuth } from "providers/AuthContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const CompanyIndustryForm = ({ currentForm, navigateToFormStep, updateCompletePercentage }: IProps) => {
  const { companyIndustry, setCompanyIndustry } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [companyIndustryUpdateLoading, setCompanyIndustryUpdateLoading] = useState(false);

  useEffect(() => {
    updateCompletePercentage(20);
  }, []);

  const companyIndustryChanged = (e: any) => {
    setCompanyIndustry(e?.target?.value);
  };

  const updateCompanyIndustry = async () => {
    try {
      setCompanyIndustryUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        industries: companyIndustry,
      };
      await apiCompanyIndustry(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setCompanyIndustryUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>{currentForm?.index}</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.formTypeText}>Free Write</div>
        <div className={styles.formQuestionText}>What industry is your company in?</div>
        <div className={styles.formQuestionDetail}>
          Using the text box below, please free write what industry your venture is in. If you need examples, check lower right side of the screen.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formTextInput}`}>
          <Input
            className={styles.companyIndustryInputBox}
            placeholder="Write your response here"
            value={companyIndustry}
            onChange={companyIndustryChanged}
            autoFocus={true}
          />
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateCompanyIndustry}
            loading={companyIndustryUpdateLoading}
            disabled={companyIndustryUpdateLoading || !companyIndustry}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next Section</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CompanyIndustryForm;
