import { Button, Checkbox, Col, Row } from "antd";
import { apiFounderGender } from "api/account-setup/founderGender";
import { IFormStep } from "interface";
import { AppContext } from "providers/app-context";
import { useAuth } from "providers/AuthContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const FounderGenderForm = ({ currentForm, navigateToFormStep, updateCompletePercentage }: IProps) => {
  const { founderGender, setFounderGender } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderGenderUpdateLoading, setFounderGenderUpdateLoading] = useState(false);

  useEffect(() => {
    updateCompletePercentage(40);
  }, []);

  const founderGenderUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderGender(updatedValue);
  };

  const updateFounderGender = async () => {
    try {
      setFounderGenderUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        gender: founderGender,
      };
      await apiFounderGender(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setFounderGenderUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>{currentForm?.index}</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.formTypeText}>Checkbox</div>
        <div className={styles.formQuestionText}>What is your gender?</div>
        <div className={styles.formQuestionDetail}>
          Select a choice below expressing your preferred gender pronoun. We use this information to create the best Founder-to-Founder groups.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`}>
          <Checkbox.Group style={{ width: "100%" }} value={[founderGender]} onChange={founderGenderUpdated}>
            <Row>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>1</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Woman</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Woman" autoFocus={true}></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>2</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Man</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Man"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>3</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Transgender</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Transgender"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>4</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Non-binary/non-conforming</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Non-binary/non-conforming"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>5</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Prefer not to respond</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Prefer not to respond"></Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateFounderGender}
            loading={founderGenderUpdateLoading}
            disabled={!founderGender || founderGenderUpdateLoading}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next Section</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FounderGenderForm;
