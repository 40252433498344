import { Button, Checkbox, Col, Row } from "antd";
import { apiFounderRace } from "api/account-setup/founderRace";
import { IFormStep } from "interface";
import { AppContext } from "providers/app-context";
import { useAuth } from "providers/AuthContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const FounderRaceForm = ({ currentForm, navigateToFormStep, updateCompletePercentage }: IProps) => {
  const { founderRace, setFounderRace } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [founderRaceUpdateLoading, setfounderRaceUpdateLoading] = useState(false);

  useEffect(() => {
    updateCompletePercentage(60);
  }, []);

  const updateFounderRace = async () => {
    try {
      setfounderRaceUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        race: founderRace,
      };
      await apiFounderRace(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setfounderRaceUpdateLoading(false);
    }
  };

  const founderRaceUpdated = (updatedValues: any[]) => {
    const updatedValue = updatedValues.pop();
    setFounderRace(updatedValue);
  };

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>{currentForm?.index}</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={`${styles.accountSetupMainFormContainer} ${styles.accountSetupMainWideFrom}`}>
        <div className={styles.formTypeText}>Checkbox</div>
        <div className={styles.formQuestionText}>What is your race?</div>
        <div className={styles.formQuestionDetail}>
          Select a choice below expressing your race. We use this information to create the best Founder-to-Founder groups.
        </div>
        <div className={`${styles.formInputContainer} ${styles.formCheckboxInput}`}>
          <Checkbox.Group style={{ width: "100%" }} value={[founderRace]} onChange={founderRaceUpdated}>
            <Row>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>1</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>American Indian</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="American Indian" autoFocus={true}></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>2</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>White</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="White"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>3</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Black</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Black"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>4</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Asian</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Asian"></Checkbox>
              </Col>
              <Col span={1}>
                <span className={styles.formCheckboxTitleNumber}>5</span>
              </Col>
              <Col span={22}>
                <span className={styles.formCheckboxTitle}>Native Hawaiian or Other Pacific Islander</span>
              </Col>
              <Col span={1}>
                <Checkbox className={styles.formCheckbox} value="Native Hawaiian or Other Pacific Islander"></Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateFounderRace}
            loading={founderRaceUpdateLoading}
            disabled={founderRaceUpdateLoading || !founderRace}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next Section</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FounderRaceForm;
