import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import * as font from '../../../core/font';

export const Plan = styled.span`
  font-family: ${font.WorkSansSemiBold};
  font-size: 18px;
  line-height: 21px;
`;

export const PlanDetails = styled.p`
  margin: 2px 0;
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
  line-height: 22px;
`;

export const Price = styled(Plan)`
  color: ${theme.primary};
`;
