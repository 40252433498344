import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { auth, database } from '../../../firebase/firebase';
import DirectMessages from "./DirectMessages";
import MastermindGroups from "./MastermindGroups";
import MsgGroup from "./MsgGroup";
import Operations from "./Operations";


const HomebaseOutgoingMsg = styled.div`
  width: 400px;
  justify-content: center;
  margin-left: 10px;
  padding: 10px;
  color: #707070;
`;
const HomebaseMsgContainer = styled.div`
  padding: 20px;
  border-radius: 20px;
`;
const Divider = styled.div`
  border-top: 1px solid #b9b9b9;
  margin: 5px;
  margin-right: 15px;
  margin-left: 15px;
`;

function MessengerThreadsPane() {
  const [mastermindExpand, setMastermindExpand] = useState(false);
  const [directMessagesExpand, setDirectMessagesExpand] = useState(false);

  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    const dbRef = database.ref('/Users/' + auth.currentUser.uid + '/Ongoing/');

    const _listener = async (roomID) => {
      const chatRoomRef = database.ref('/Chat/' + roomID);
      const chatType = (
        await chatRoomRef.child('/ChatType').once('value')
      ).val();
      const roomColor = (
        await chatRoomRef.child('/RoomColor').once('value')
      ).val();
      const users = (await chatRoomRef.child('/Users/uIDs').once('value'))
        .val()
        .filter((e) => e != null);
      return {
        id: roomID,
        data: {
          ChatType: chatType,
          RoomColor: roomColor,
          uIDs: users,
        },
      };
    };

    dbRef.on(
      'value',
      (snapshot) => {
        if (snapshot.val() !== null) {
          Promise.all(
            Object.values(snapshot.val()).map((e) => _listener(e.roomID)),
          )
            .then((val) => {
              setRooms(val)
            })
            .catch(() => { });
        }
      },
      (e) => console.log('leftdrawer/ongoingmsg.jsx - listener', e),
    );
    return () => dbRef.off();
  }, []);

  useEffect(() => {
    let mounted = true;
    return () => mounted = false;
  });

  return (
    <>
      <Operations />
      <MastermindGroups
        expanded={mastermindExpand}
        setExpanded={setMastermindExpand}
      />
      <Divider />
      <DirectMessages
        messages={rooms}
        expanded={directMessagesExpand}
        setExpanded={setDirectMessagesExpand}
      />
      <HomebaseOutgoingMsg>
        <strong>Ongoing Messages </strong>
      </HomebaseOutgoingMsg>
      <Divider />
      <HomebaseMsgContainer>
        {rooms.map((ele, i) => (
          <MsgGroup
            id={ele.id}
            users={ele.data.uIDs}
            roomColor={ele.data.RoomColor}
            chatType={ele.data.ChatType}
            // _join={_join} 
            key={"msgGroup" + i} />
        ))}
      </HomebaseMsgContainer>
    </>
  );
}

export default MessengerThreadsPane;
