import { IFormStep } from "interface";
import styles from "./index.module.scss";

interface IProps {
  currentForm: IFormStep;
}

const LeftSideBarAccountSetupProgressMenuComponent = ({ currentForm }: IProps) => {
  return (
    <div className={styles.accountSetupMenu}>
      <div className={styles.accountSetupMenuMainTitle}>Account Setup</div>
      <div className={styles.accountSetupMenuContainer}>
        <div className={`f-d-flex f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "01" && styles.active}`} style={{ gap: 25 }}>
          <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Company Stage</span>
        </div>
        <div className={`f-d-flex  f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "02" && styles.active}`} style={{ gap: 25 }}>
          <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Company Industry</span>
        </div>
        <div className={`f-d-flex  f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "03" && styles.active}`} style={{ gap: 25 }}>
          <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Gender</span>
        </div>
        <div className={`f-d-flex  f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "04" && styles.active}`} style={{ gap: 25 }}>
          <span className={` ${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Race</span>
        </div>
        <div className={`f-d-flex  f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "05" && styles.active}`} style={{ gap: 25 }}>
          <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Physical Mailing Address</span>
        </div>
        <div className={`f-d-flex  f-py-8 ${styles.accountSetupMenuItem} ${currentForm?.index === "06" && styles.active}`} style={{ gap: 25 }}>
          <span className={`${styles.accountSetupMenuDot}`}>&nbsp;</span>
          <span className={styles.accountSetupMenuTitle}>Matching</span>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBarAccountSetupProgressMenuComponent;
