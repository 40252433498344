import React from 'react';
import * as SC from './RightContainerStyles';
import * as GlobalSC from '../../GlobalComponents';
import { useNavigate } from 'react-router-dom';

const arrow = require('../../assets/image/arrow_back.png');

function RightContainer() {
  const navigate = useNavigate();
  const goBack = () => navigate('/office');

  return (
    <GlobalSC.RightSideContainer>
      <GlobalSC.SubContainer>
        <GlobalSC.FormContainer>
          <GlobalSC.DPFlex>
            <GlobalSC.Title>404 Error</GlobalSC.Title>
            <GlobalSC.Description>
              Looks like you're lost in the&nbsp;
              <SC.Highlight>Mountains</SC.Highlight>
            </GlobalSC.Description>
            <SC.Comment>
              Unfortunately, this page doesn't exist but our support for you
              still does
            </SC.Comment>
          </GlobalSC.DPFlex>
        </GlobalSC.FormContainer>
      </GlobalSC.SubContainer>
      <SC.ButtonContainer>
        <SC.BackButton onClick={goBack}>
          <img src={arrow} alt="next btn" width={30} />
        </SC.BackButton>
        <SC.GoBack>Go Back</SC.GoBack>
      </SC.ButtonContainer>
    </GlobalSC.RightSideContainer>
  );
}

export default RightContainer;
