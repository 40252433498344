import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import * as font from '../../../core/font';
import { SubContainer } from '../Components';

export const LocalSubContainer = styled(SubContainer)`
  margin-top: 0px;
  @media screen and (max-width: 700px) {
    margin-top: 15px;
  }
`;

export const StartBackButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.bgColor || `inherit`};
  outline: none;
  border-radius: 15px;
  box-shadow: ${(props) =>
    `0 0 ${props.active ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
  animation: fadeIn ease 2s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const StartBackButtonText = styled.span`
  color: ${(props) => (props.active ? `white` : `black`)};
  opacity: ${(props) => (props.active ? `100%` : `49%`)};
  font-family: ${font.WorkSansSemiBold};
  font-size: 20px;
  margin-right: 10px;
`;
