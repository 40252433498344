import React from "react";
import { useState } from "react";
import * as SC from "./RightContainerStyles";
import * as RegionalSC from "../Components";
import * as GlobalSC from "../../../GlobalComponents";
import theme from "../../../core/theme";

const arrow = require("../../../assets/image/arrow_next.png");

function RightContainer() {
  const [plan, setPlan] = useState(2);
  const onSignUp = () => {
    console.log(plan);
  };
  return (
    <GlobalSC.RightSideContainer>
      <GlobalSC.SubContainer>
        <RegionalSC.EasterEgg />
        <GlobalSC.FormContainer>
          <GlobalSC.DPFlex>
            <GlobalSC.Title>Learn More</GlobalSC.Title>
            <GlobalSC.Description>
              Watch the video to learn more about Framewrk and sign-up For a
              6-day free trial. If you're ready to take full leap, Sign up for a
              subscription plan!
            </GlobalSC.Description>
          </GlobalSC.DPFlex>
          <RegionalSC.CheckBox
            large
            checked={plan === 0}
            onChange={() => setPlan(0)}
            label={
              <SC.Plan>
                Free Plan
                <SC.PlanDetails>- Personalized AI consultant</SC.PlanDetails>
                <SC.PlanDetails>- Partner Mode</SC.PlanDetails>
              </SC.Plan>
            }
          ></RegionalSC.CheckBox>
          <RegionalSC.CheckBox
            large
            checked={plan === 1}
            onChange={() => setPlan(1)}
            label={
              <SC.Plan>
                Standard Plan&nbsp;
                <SC.Price>[$10.99/month]</SC.Price>
                <SC.PlanDetails>- Personalized AI consultant</SC.PlanDetails>
                <SC.PlanDetails>- Partner Mode</SC.PlanDetails>
                <SC.PlanDetails>- Recommendations + Workshops</SC.PlanDetails>
              </SC.Plan>
            }
          ></RegionalSC.CheckBox>
          <RegionalSC.CheckBox
            large
            checked={plan === 2}
            onChange={() => setPlan(2)}
            label={
              <SC.Plan>
                Premium Plan&nbsp;
                <SC.Price>[$39.99/month]</SC.Price>
                <SC.PlanDetails>- Personalized AI consultant</SC.PlanDetails>
                <SC.PlanDetails>- Partner Mode</SC.PlanDetails>
                <SC.PlanDetails>- Recommendations + Workshops</SC.PlanDetails>
                <SC.PlanDetails>- Mastermind Groups</SC.PlanDetails>
              </SC.Plan>
            }
          ></RegionalSC.CheckBox>
          <RegionalSC.CheckBox
            large
            checked={plan === 3}
            onChange={() => setPlan(3)}
            label={
              <SC.Plan>
                Unicorn Plan&nbsp;
                <SC.Price>[$TBD]</SC.Price>
                <SC.PlanDetails>- Personalized AI consultant</SC.PlanDetails>
                <SC.PlanDetails>- Partner Mode</SC.PlanDetails>
                <SC.PlanDetails>- Recommendations + Workshops</SC.PlanDetails>
                <SC.PlanDetails>- Mastermind Groups</SC.PlanDetails>
                <SC.PlanDetails>- Team Mode</SC.PlanDetails>
              </SC.Plan>
            }
          ></RegionalSC.CheckBox>
        </GlobalSC.FormContainer>
      </GlobalSC.SubContainer>
      <GlobalSC.ButtonContainer onClick={onSignUp}>
        <GlobalSC.NextButtonText>
          6-Day Free Trial Sign Up
        </GlobalSC.NextButtonText>
        <GlobalSC.NextButton active bgColor={theme.secondary}>
          <img src={arrow} alt="next btn" width={30} />
        </GlobalSC.NextButton>
      </GlobalSC.ButtonContainer>
    </GlobalSC.RightSideContainer>
  );
}

export default RightContainer;
