import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/styles';
import { ConfigProvider } from "antd";
import 'antd/dist/reset.css';
import { AppContextProvider } from 'providers/app-context';
import { Navigate, Route, Routes } from 'react-router-dom';
import AccountSetup from 'screens/AccountSetup';
import ChooseOffice from 'screens/ConfigureOffice/ChooseOffice';
import EngagementLoading from 'screens/ConfigureOffice/EngagementLoading';
import OfficeReady from 'screens/ConfigureOffice/OfficeReady';
import NewLogin from 'screens/NewAuth/NewLogin';
import NewSignUp from 'screens/NewAuth/NewSignup';
import Office from 'screens/Office';
import BackgroundVideo from 'screens/Office/Components/BackgroundVideo';
import Roadmap from 'screens/Roadmap';
import theme from './core/theme';
import PrivateRoute from './GlobalComponents/PrivateRoute';
import NotFound from './screens/404';
import Account from './screens/Account';
import Guest from './screens/Account/guest';
import LearnMore from './screens/Auth/LearnMore';

const TermsOfUse = () => {
  window.location.replace('https://www.framewrkagency.com/privacypolicy');
  return null;
};

// Theme factory
function createMyTheme(options) {
  return createTheme({
    typography: {
      button: {
        textTransform: 'none'
      }
    },
    overrides: {
      MuiPickersBasePicker: {
        container: {
          backgroundColor: "#fff",
        },
      },
      MuiPickersCalendarHeader: {
        iconButton: {
          backgroundColor: "#fff"
        }
      },
    },
    ...options
  })
};
// Initialize theme
const MuiTheme = createMyTheme({
  palette: {
    background: {
      default: theme.background,
    },
    primary: {
      main: theme.primary
    },
    secondary: {
      main: theme.secondary
    }
  },
});

function App() {

  return (
    <ThemeProvider theme={MuiTheme}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#CFCF22",
          },
        }}
      >
        <AppContextProvider>
          <Routes>
            <Route exact path="/" element={<Navigate to="/login" replace />} />
            <Route exact path="/signup" element={<NewSignUp />} />
            <Route exact path="/login" element={<NewLogin />} />
            <Route exact path="/terms-of-use" element={<TermsOfUse />} />
            <Route exact path="/learn-more" element={<LearnMore />} />
            <Route exact path="/account/:email" element={<Guest />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route path="account-setup" element={<AccountSetup />} />
              <Route exact path="/choose-your-office" element={<ChooseOffice />} />
              <Route exact path="/engagement-loading" element={<EngagementLoading />} />
              <Route exact path="/office-ready" element={<OfficeReady />} />
              <Route path="/office" element={<Office />} >
                <Route exact path={"/office"} element={<BackgroundVideo />} />
                <Route path={"/office/roadmap/*"} element={<Roadmap />}></Route>
              </Route>
              <Route path="/account" element={<Account />} />
            </Route>
            <Route path='*' element={<NotFound />} />
          </Routes>
        </AppContextProvider>
      </ConfigProvider>
    </ThemeProvider >
  );
}

export default App;
