import { Button } from "antd";
import { IFormStep } from "interface";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./../index.module.scss";

interface IProps {
  currentForm: IFormStep;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const CongratulationFormComplete = ({ currentForm, updateCompletePercentage }: IProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    updateCompletePercentage(100);
  }, []);

  const loadDeepLinkUrl = () => {
    // DEV TODO: add deep link value here when get from mobile dev
    // if (isAndroid) {
    //   const url =
    //     "intent://instagram.com/#Intent;scheme=https;package=com.instagram.android;end";

    //   window.location.replace(url);
    // } else if (isIOS) {
    //   window.location.replace("instagram://");

    //   setTimeout(() => {
    //     window.location.replace(
    //       "https://apps.apple.com/us/app/instagram/id389801252"
    //     );
    //   }, 10000);
    // } else {
    //   navigate('/choose-your-office');
    // }
    navigate('/choose-your-office');
  }

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>&nbsp;</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.formTypeText}>Account Setup Complete</div>
        <div className={styles.formQuestionText}>You don't have to wait to get matched, you can Start exploring more</div>
        <div className={`${styles.formInputContainer} ${styles.formCompleteCardContainer}`}>
          <div className={styles.formCompleteInfoCardContainer}>
            <div className={styles.formCompleteInfoCardNumber}>1</div>
            <div className={styles.formCompleteInfoCardTitle}>Download the mobile app</div>
            <div className={styles.formCompleteInfoCardDetail}>
              Check your email for a link to download the mobile app. Sign-in on your phone and experience something you've never seen before.
            </div>
          </div>

          <div className={styles.formCompleteInfoCardContainer}>
            <div className={styles.formCompleteInfoCardNumber}>2</div>
            <div className={styles.formCompleteInfoCardTitle}>Explore the web app</div>
            <div className={styles.formCompleteInfoCardDetail}>
              Check out more of the web experience and go through onboarding to get to work while we match you to other great Founders.
            </div>
          </div>

          <div className={styles.formCompleteInfoCardContainer}>
            <div className={styles.formCompleteInfoCardNumber}>3</div>
            <div className={styles.formCompleteInfoCardTitle}>Wait to get matched</div>
            <div className={styles.formCompleteInfoCardDetail}>
              Just wait until you're matched to other Founders. We'll email you when we've created a group for you.
            </div>
          </div>
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={`${styles.accoutSetupNextSectionButton} ${styles.CompleteExploreFramewrkButton}`}
            onClick={loadDeepLinkUrl}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Explore Framewrk</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CongratulationFormComplete;
