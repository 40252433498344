import { loadStripe } from '@stripe/stripe-js';

export const emailValidator = (email) => {
  const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
  const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
  const sAtom =
    '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
  const sQuotedPair = '\\x5c[\\x00-\\x7f]';
  const sDomainLiteral = `\\x5b(${sDtext}|${sQuotedPair})*\\x5d`;
  const sQuotedString = `\\x22(${sQtext}|${sQuotedPair})*\\x22`;
  const sDomain_ref = sAtom;
  const sSubDomain = `(${sDomain_ref}|${sDomainLiteral})`;
  const sWord = `(${sAtom}|${sQuotedString})`;
  const sDomain = `${sSubDomain}(\\x2e${sSubDomain})*`;
  const sLocalPart = `${sWord}(\\x2e${sWord})*`;
  const sAddrSpec = `${sLocalPart}\\x40${sDomain}`;
  const sValidEmail = `^${sAddrSpec}$`;
  const re = new RegExp(sValidEmail);
  if (!email || email.length <= 0) {
    return 'Email cannot be empty.';
  }
  if (!re.test(email)) {
    return 'Ooops! We need a valid email address.';
  }
  return '';
};

export const passwordValidator = (password) => {
  if (!password || password.length <= 0) {
    return 'Password cannot be empty.';
  }
  return '';
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

let stripe

export const getStripe = async () => {
  if (!stripe) {
    stripe = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    )
  }
  return stripe
}
