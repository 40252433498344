import { Mastermind } from '../../../../core/resourceURIs';

export const getText = (theme) => {
  const pageTitle = `Welcome to the ${
    theme === 'Operations' ? 'Ops' : theme
  } Mastermind`;
  const videoTitle = 'What is a Mastermind Group?';
  let description;
  let uri;
  switch (theme) {
    case 'Operations':
      description =
        'This group is all about connecting people together who can help each other create a better operational system: people, processes and technology. Everyone in the mastermind will share suggestions about how to create an efficient system.';
      uri = Mastermind.OpsMastermind;
      break;
    case 'Marketing':
      description =
        'More customers. More sales. More traffic to your website or store. This Mastermind Group is all about sharing secrets that you’ve used or other entrepreneurs have used to improve their marketing. Now, let’s get more sales!';
      uri = Mastermind.MarketingMastermind;
      break;
    case 'Finance':
      description =
        'The Finance Mastermind is about having those tough conversations about your finances and profitability. It’s about opening yourself up to critical conversations and making your business stand the test of financial time. Be willing to share but more importantly, be willing to grow your profit.';
      uri = Mastermind.FinanceMastermind;
      break;
    case 'Risk':
      description =
        'Every decision we make has risks. So how do you make the best decisions even though there are risks everywhere? That’s what this Mastermind Group is about. Sharing information to make better decisions.';
      uri = Mastermind.RiskMastermind;
      break;
    case 'Competitors':
      description =
        'What are some good strategies for beating your competitors? Learn from other entrepreneurs who have secrets that they’ve used to win in the market. Have information you’d like to share with others? Tell them and help them grow their business too.';
      uri = Mastermind.CompetitorMastermind;
      break;
    case 'Legal':
      description =
        'Don’t get sued. Don’t get screwed. That’s the mantra and this group is about sharing legal shit we’ve fallen for before, and the things we need to know so none of us have to experience it again. We’ll talk about experiences, documents, strategies, and what we should look for in lawyers.';
      uri = Mastermind.LegalMastermind;
      break;
    case 'Team':
      description =
        'How to hire people. How to fire people, because trust us that’s often overlooked. Who are the people you need on your team? How do you strengthen your communication to get more $hit done and less time wasted.That’s what this Mastermind Group is all about.';
      uri = Mastermind.TeamMastermind;
      break;
    case 'Self':
      description =
        'This group is all about connecting people together who can help each other create a better operational system: people, processes and technology. Everyone in the mastermind will share suggestions about how to create an efficient system.';
      uri = Mastermind.SelfMastermind;
      break;
    case 'Branding':
      description =
        'What does your company stand for? What are its core principles, what is its ‘why’? In the Branding Mastermind, you’ll connect with other entrepreneurs who are building amazing brands. Talk about what a brand is, how to communicate it, and how to make it last.';
      uri = Mastermind.BrandingMastermind;
      break;
    default:
      break;
  }
  return {
    pageTitle,
    description,
    videoTitle,
    uri,
  };
};
