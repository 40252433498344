import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Drawer, IconButton, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Context } from '../../../providers/Context';
import * as font from './../../../core/font';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 60,
        [theme.breakpoints.up('sm')]: {
            width: 60,
        },
    },
    menuContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '50px',
        cursor: 'pointer',
        '&:focus, &:hover': {
            backgroundColor: '#B9B9B94D',
        },
    },
    activeMenu: {
        backgroundColor: '#F8F2E1',
        borderRight: '5px solid #CFCF22'
    },
    menuTitle: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0'
    },
    menuIconContainer: {
        margin: '0 10px',
        minHeight: '39px',
        minWidth: '39px',
        maxHeight: '39px',
        maxWidth: '39px',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: '2px'
    },
    menuTitleMain: {
        fontSize: '16px',
        fontFamily: font.WorkSansMedium,
        color: '#2C2C2C'
    },
    menuTitleSecondary: {
        fontSize: '12px',
        fontFamily: font.CircularTTMedium,
        color: '#B9B9B9'
    }
}));

const SideBarWrapper = styled.div``

function SideBar(props) {
    const classes = useStyles();

    const { setHomeBaseToggle } = useContext(Context)

    const [expanded, setExpanded] = useState(false)

    const navigate = useNavigate();
    return (
        <SideBarWrapper>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: expanded,
                    [classes.drawerClose]: !expanded,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: expanded,
                        [classes.drawerClose]: !expanded,
                    }),
                }}>
                <List>
                    <div
                        className={`${classes.menuContainer} ${classes.activeMenu}`}
                        onClick={() => {
                            navigate("/office")
                            setHomeBaseToggle(false)
                        }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#CFCF22' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22.489" height="24.766" viewBox="0 0 22.489 24.766">
                                <g id="home" transform="translate(1 1)">
                                    <path id="Path_158" data-name="Path 158" d="M3,9.968,13.245,2,23.489,9.968V22.489a2.277,2.277,0,0,1-2.277,2.277H5.277A2.277,2.277,0,0,1,3,22.489Z" transform="translate(-3 -2)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_159" data-name="Path 159" d="M9,23.383V12h6.83V23.383" transform="translate(-2.17 -0.617)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Dashboard</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#E5A4C1' }}>
                            <svg id="settings" xmlns="http://www.w3.org/2000/svg" width="23.051" height="23.3" viewBox="0 0 23.051 23.3">
                                <circle id="Ellipse_237" data-name="Ellipse 237" cx="3.5" cy="3.5" r="3.5" transform="translate(8 8)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                <path id="Path_342" data-name="Path 342" d="M18.607,14.555a1.612,1.612,0,0,0,.316,1.762l.057.058a1.953,1.953,0,0,1,0,2.74,1.9,1.9,0,0,1-2.708,0l-.057-.058a1.565,1.565,0,0,0-1.742-.32,1.6,1.6,0,0,0-.957,1.462v.165a1.914,1.914,0,1,1-3.828,0v-.087a1.6,1.6,0,0,0-1.033-1.462,1.565,1.565,0,0,0-1.742.32l-.057.058a1.9,1.9,0,0,1-2.708,0,1.953,1.953,0,0,1,0-2.74l.057-.058a1.612,1.612,0,0,0,.316-1.762,1.579,1.579,0,0,0-1.445-.968H2.914a1.937,1.937,0,0,1,0-3.873H3A1.58,1.58,0,0,0,4.445,8.746a1.612,1.612,0,0,0-.316-1.762l-.057-.058a1.953,1.953,0,0,1,0-2.74,1.9,1.9,0,0,1,2.708,0l.057.058a1.565,1.565,0,0,0,1.742.32h.077A1.6,1.6,0,0,0,9.612,3.1V2.936a1.914,1.914,0,1,1,3.828,0v.087A1.6,1.6,0,0,0,14.4,4.486a1.565,1.565,0,0,0,1.742-.32l.057-.058a1.9,1.9,0,0,1,2.708,0,1.953,1.953,0,0,1,0,2.74l-.057.058a1.612,1.612,0,0,0-.316,1.762v.077a1.579,1.579,0,0,0,1.445.968h.163a1.937,1.937,0,0,1,0,3.873h-.086A1.579,1.579,0,0,0,18.607,14.555Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Operations</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#999EE1' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
                                <g id="eye" transform="translate(0 -3)">
                                    <path id="Path_343" data-name="Path 343" d="M1,12S5,4,12,4s11,8,11,8-4,8-11,8S1,12,1,12Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <circle id="Ellipse_238" data-name="Ellipse 238" cx="3" cy="3" r="3" transform="translate(9 9)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Branding</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#4EBA9A' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="35.326" height="35.326" viewBox="0 0 35.326 35.326">
                                <g id="megaphone" transform="translate(18.882 0) rotate(45)">
                                    <g id="Group_674" data-name="Group 674" transform="translate(0 0)">
                                        <g id="Group_673" data-name="Group 673" transform="translate(0)">
                                            <path id="Path_294" data-name="Path 294" d="M22.544,9.9,13.352.713A2.437,2.437,0,0,0,9.609,3.8L9.6,3.864A17.006,17.006,0,0,1,4.93,12.581L.714,16.8a2.436,2.436,0,0,0,0,3.447l2.3,2.3a2.436,2.436,0,0,0,3.447,0l.574-.574,4.021,4.021A2.437,2.437,0,0,0,14.5,22.542l-1.723-1.723.574-.574a2.436,2.436,0,0,0,0-3.447l-.39-.39a17.008,17.008,0,0,1,6.431-2.747l.065-.013a2.44,2.44,0,0,0,3.086-.3h0A2.44,2.44,0,0,0,22.544,9.9ZM5.31,21.393a.812.812,0,0,1-1.149,0l-2.3-2.3a.812.812,0,0,1,0-1.149l4.021-4.021,3.447,3.447Zm8.042,2.3A.812.812,0,0,1,12.2,24.84L8.182,20.818l1.149-1.149Zm-2.872-5.17c.244-.244.61-.618,1.187-1.111l.537.537a.812.812,0,0,1,0,1.149l-.574.574Zm.035-2.263L7,12.742a18.6,18.6,0,0,0,3.955-7.534L18.048,12.3A18.6,18.6,0,0,0,10.515,16.257ZM21.395,12.2a.812.812,0,0,1-1.149,0L11.054,3.01A.812.812,0,0,1,12.2,1.862l9.191,9.191A.813.813,0,0,1,21.395,12.2Z" transform="translate(0 0)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Marketing</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#86CE76' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24.438" height="20.49" viewBox="0 0 24.438 20.49">
                                <g id="suitcase" transform="translate(0.55 -1.5)">
                                    <g id="Group_665" data-name="Group 665" transform="translate(-0.001 4.924)">
                                        <path id="Path_220" data-name="Path 220" d="M20.952,21.567H2.436A2.413,2.413,0,0,1,0,19.13V7.436A2.413,2.413,0,0,1,2.436,5H20.952a2.413,2.413,0,0,1,2.436,2.436V19.13A2.413,2.413,0,0,1,20.952,21.567ZM2.436,5.975A1.5,1.5,0,0,0,.975,7.436V19.13a1.5,1.5,0,0,0,1.462,1.462H20.952a1.5,1.5,0,0,0,1.462-1.462V7.436a1.5,1.5,0,0,0-1.462-1.462Z" transform="translate(0 -5)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                    </g>
                                    <g id="Group_666" data-name="Group 666" transform="translate(7.795 2)">
                                        <path id="Path_221" data-name="Path 221" d="M15.309,5.9a.46.46,0,0,1-.487-.487V3.462a.46.46,0,0,0-.487-.487H9.462a.46.46,0,0,0-.487.487V5.411a.487.487,0,0,1-.975,0V3.462A1.5,1.5,0,0,1,9.462,2h4.873A1.5,1.5,0,0,1,15.8,3.462V5.411A.46.46,0,0,1,15.309,5.9Z" transform="translate(-8 -2)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                    </g>
                                    <g id="Group_667" data-name="Group 667" transform="translate(-0.027 8.785)">
                                        <path id="Path_222" data-name="Path 222" d="M11.693,13.872H11.5L.292,9.974A.594.594,0,0,1,0,9.291.594.594,0,0,1,.681,9l11.012,3.9L22.705,9a.409.409,0,0,1,.585.292A.409.409,0,0,1,23,9.876l-11.207,3.9c0,.1,0,.1-.1.1Z" transform="translate(0.027 -8.963)" fill="#fff" stroke="#fff" strokeWidth="1" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Finance Room</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#FF7E7E' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22">
                                <g id="flag" transform="translate(-3 -1)">
                                    <path id="Path_200" data-name="Path 200" d="M4,15s1-1,4-1,5,2,8,2,4-1,4-1V3s-1,1-4,1S11,2,8,2,4,3,4,3Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <line id="Line_239" data-name="Line 239" y1="7" transform="translate(4 15)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Team</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#2B4735' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="19.49" height="23.1" viewBox="0 0 19.49 23.1">
                                <g id="_003-verified" data-name="003-verified" transform="translate(-40 0)">
                                    <path id="Path_199" data-name="Path 199" d="M58.934,3.741,50.091.069a.9.9,0,0,0-.692,0L40.556,3.741A.9.9,0,0,0,40,4.574V9.037a15.111,15.111,0,0,0,9.4,14,.9.9,0,0,0,.682,0,15.111,15.111,0,0,0,9.4-14V4.574A.9.9,0,0,0,58.934,3.741Zm-1.248,5.3a13.309,13.309,0,0,1-7.941,12.18A13.31,13.31,0,0,1,41.8,9.037V5.176l7.941-3.3,7.941,3.3Z" transform="translate(0)" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Competitors</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#82C4D2' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <g id="alert-circle" transform="translate(-1 -1)">
                                    <circle id="Ellipse_239" data-name="Ellipse 239" cx="10.5" cy="10.5" r="10.5" transform="translate(2 2)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <line id="Line_360" data-name="Line 360" y2="5" transform="translate(12.5 8)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <line id="Line_361" data-name="Line 361" x2="0.01" transform="translate(12.5 16.7)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Risk</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#D2B35E' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="21.512" height="21.512" viewBox="0 0 21.512 21.512">
                                <g id="pen-tool" transform="translate(-1 -1)">
                                    <path id="Path_210" data-name="Path 210" d="M12,18.829,18.829,12l2.927,2.927-6.829,6.829Z" transform="translate(-0.244 -0.244)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_211" data-name="Path 211" d="M17.609,12.731,16.146,5.415,2,2,5.415,16.146l7.317,1.463Z" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_212" data-name="Path 212" d="M2,2,9.4,9.4" fill="none" stroke="#fff" strokeLinejoin="round" strokeWidth="2" />
                                    <circle id="Ellipse_161" data-name="Ellipse 161" cx="2" cy="2" r="2" transform="translate(8.5 8.328)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Legal</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    <div
                        className={`${classes.menuContainer}`}
                        onClick={() => { }}>
                        <div className={classes.menuIconContainer} style={{ backgroundColor: '#D273B2' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23">
                                <g id="smile" transform="translate(-1 -1)">
                                    <circle id="Ellipse_240" data-name="Ellipse 240" cx="10.5" cy="10.5" r="10.5" transform="translate(2 2)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <path id="Path_344" data-name="Path 344" d="M8,14a5.669,5.669,0,0,0,4.2,2.1A5.669,5.669,0,0,0,16.4,14" transform="translate(0.3 0.6)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <line id="Line_362" data-name="Line 362" x2="0.01" transform="translate(9.35 9.35)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                    <line id="Line_363" data-name="Line 363" x2="0.01" transform="translate(15.65 9.35)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                </g>
                            </svg>
                        </div>
                        <div className={classes.menuTitle}>
                            <div className={classes.menuTitleMain}>Self</div>
                            <div className={classes.menuTitleSecondary}>Take some time to vent.</div>
                        </div>
                    </div>

                    {/* <ListItem button onClick={() => setRoadmapPathPushHistory("/office/roadmap/workshopinvite")}>
                        <ListItemIcon><SecurityIcon /></ListItemIcon>
                        <ListItemText primary={"Workshop Invite"} />
                    </ListItem>
                    <ListItem button onClick={() => setRoadmapPathPushHistory("/office/roadmap/workshopsurvey")}>
                        <ListItemIcon><FlagIcon /></ListItemIcon>
                        <ListItemText primary={"Workshop Survey"} />
                    </ListItem>
                    <ListItem button onClick={() => setRoadmapPathPushHistory("/office/roadmap/workshop/infopane/")}>
                        <ListItemIcon><WorkIcon /></ListItemIcon>
                        <ListItemText primary={"Workshop Info"} />
                    </ListItem> */}
                </List>
                {
                    expanded ?
                        <div style={{ position: "absolute", left: "180px", bottom: "10px" }}>
                            <IconButton onClick={() => setExpanded(false)}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        :
                        <div style={{ position: "absolute", left: "5px", bottom: "10px" }}>
                            <IconButton onClick={() => setExpanded(true)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </div>
                }

            </Drawer >
        </SideBarWrapper >
    )
}

export default SideBar
