import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { getRandomColor } from '../core/utils';
import { auth, database, messaging } from '../firebase/firebase';

const AuthContext = React.createContext()

const { REACT_APP_BACKEND_API_URL: baseUrl } = process.env;

export function useAuth() {
  return useContext(AuthContext)
}

//Main Function
function AuthProvider({ children, localStorageToken }) {
  const [currentUser, setCurrentUser] = useState();
  const [stripeSubscription, setStripeSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userAuthToken, setUserAuthToken] = useState(localStorageToken);

  const detachListeners = () => {
    const uID = auth.currentUser.uid;
    database.ref(`/Users/${uID}/PendingInvites/`).off();
    database.ref(`/Users/${uID}/Partners/`).off();
    database.ref(`/Users/${uID}/Invitations/`).off();
    database.ref(`/Users/${uID}/Ongoing/`).off();
    database.ref(`/Users/${uID}/Recommended/`).off();
    database.ref(`/Diagnostics/${uID}`).off();
  };

  //signup method
  async function Signup(email, password, name) {
    const user = await auth.createUserWithEmailAndPassword(email, password)
    let token = ''
    try {
      token = await messaging.getToken();
      await user.user.updateProfile({
        displayName: name
      })
    } catch (err) {
      console.log('Error on getting notification permissions', err)
    }
    const userId = auth.currentUser.uid
    const userPayload = {
      BasicInfo: {
        Email: email,
        Username: name,
        AvatarColor: getRandomColor(),
        companyName: ''
      },
      CreatedAt: new Date(auth.currentUser.metadata.creationTime).getTime(),
      isNewUser: true,
      LastOnline: Date.now(),
      LastDailyQAVisited: Date.now(),
      deviceToken: null,
      Profile: {
        Address: '',
        ShortBio: '',
        Milestones: {
          milestone1: '',
          milestone2: '',
          milestone3: '',
        },
        Specialities: {
          specialities1: '',
          specialities2: '',
          specialities3: '',
        },
      },
    }
    if (token) {
      userPayload.deviceToken = token
    }
    await database.ref(`/Users/${userId}`).update(userPayload);
    await database.ref(`/UnGroupedUser/${userId}/`).update({
      CompanyIndustry: '',
      CompanyStage: '',
      FounderRace: '',
      Gender: ''
    })
    return user
  }

  //login method
  async function Login(email, password) {
    try {

      await auth.signInWithEmailAndPassword(email, password);

      const token = await messaging.getToken();
      await database
        .ref('/Users/' + auth.currentUser.uid + '/deviceToken/')
        .update({ [token]: true });
      return {
        uid: auth.currentUser.uid,
        lastSignInTime: auth.currentUser.metadata.lastSignInTime
      };
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          return {
            error: 'Invalid email address format.',
          };
        case 'auth/user-not-found':
        case 'auth/wrong-password':
          return {
            error: 'Invalid email address or password.',
          };
        case 'auth/too-many-requests':
          return {
            error: 'Too many request. Try again in a minute.',
          };
        default:
          return {
            error: 'Check your internet connection.',
          };
      }
    }
  }

  //logout method
  async function Logout(email, password) {
    try {
      const token = await messaging.getToken();
      await database
        .ref('/Users/' + auth.currentUser.uid + '/deviceToken/')
        .update({ [token]: false });
      detachListeners();
      setStripeSubscription(null)
      localStorage.removeItem('framewrk_user_token');
      await auth.signOut();
      return {};
    } catch (e) {
      return { error: e };
    }
  }

  async function recover(email) {
    await auth.sendPasswordResetEmail(email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      setCurrentUser(user)
      if (user) {
        const idToken = await user.getIdToken()
        const url = `${baseUrl}/users/me`;
        const response = await axios.get(url, { headers: { Authorization: `Bearer ${idToken}` } });
        setStripeSubscription(response?.data?.stripeSubscription?.[0]);
      }
      setLoading(false)
    });
    return unsubscribe
  }, [])

  const value = {
    currentUser,
    stripeSubscription,
    Signup,
    Login,
    Logout,
    userAuthToken,
    setUserAuthToken,
    recover
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>)
}

export default AuthProvider;



