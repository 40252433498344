import styled from 'styled-components/macro';
import theme from '../../core/theme';
import backgroundImage from '../../assets/image/bg.png';

export const LeftContainer = styled.div`
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)),
    url(${backgroundImage}) no-repeat;
  background-size: cover;
  color: whitesmoke;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 72%;
  @media screen and (max-width: 700px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem;
  width: fit-content;
`;

export const VerticalLine = styled.div`
  background-color: ${theme.disabled};
  display: block;
  height: 30rem;
  margin: 2rem 0;
  width: 1px;
`;

export const TestmonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Testimonial = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 24px;
`;

export const NameContainer = styled.div`
  margin-left: 20%;
`;

export const Name = styled.span`
  font-size: 15px;
  line-height: 18px;
`;

export const NameUnderline = styled.div`
  background-color: ${theme.secondary};
  height: 4px;
  margin-top: 8px;
  width: 15%;
`;
