import styles from "./index.module.scss";

const RightSideBarIndexComponent = () => {
  return (
    <div className={styles.accountSetupIndexComponent}>
      <div className={styles.accountSetupIndexTitle}>Index</div>
      <div>
        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Ideation</div>
          <div className={styles.accountSetupIndexDetailText}>
            You have an idea that you're exploring, you're not incorporated yet,
            but want to figure out if this thing can work!
          </div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Validation</div>
          <div className={styles.accountSetupIndexDetailText}>
            You may or may not be incorporated but you're starting to see early
            signs of people wanting your business. You still have some
            validating to do but you're on the path.
          </div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Operational</div>
          <div className={styles.accountSetupIndexDetailText}>
            You're building your business. You have a small team or are starting
            to think about building a team to get all of your processes in
            place.
          </div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Growth</div>
          <div className={styles.accountSetupIndexDetailText}>
            You're generating income or at least have people using your product
            or service. This time is about speeding up your growth.
          </div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>
            Establishing
          </div>
          <div className={styles.accountSetupIndexDetailText}>
            You're profitable or close to profitable. You're continuing to
            establish your company, the brand, and its impact.
          </div>
        </div>
        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Exiting</div>
          <div className={styles.accountSetupIndexDetailText}>
            You're at the stage where you're trying to sell your company or wind
            it down.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBarIndexComponent;
