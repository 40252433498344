import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { InputAdornment, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import LightningCloudSVG from '../../../assets/image/lightningCloudIcon.svg';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import { TextInput } from '../../../GlobalComponents';
import { Context } from '../../../providers/Context';
import MailIcon from '../../Auth/Components/MailIcon';
import UserIcon from '../../Auth/Components/UserIcon';

function ErrorComponent({ error }) {
    if (!error) {
        return null;
    }
    return (
        <Tooltip title={<span style={{ fontSize: '15px', fontFamily: font.WorkSansMedium }}>{error}</span>} placement="top" arrow >
            <HighlightOffIcon
                style={{
                    height: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 10,
                    color: 'red'
                }}
            />
        </Tooltip>
    );
}

const InviteTitle = styled.h2`
    font-family: ${font.WorkSansSemiBold};
    font-size: 18px;
    opacity: 31%;
`

const InviteSubtitle = styled.h3`
    font-family: ${font.CircularTTMedium};
    font-size: 12px;
    opacity: 80%;
    margin-bottom: 15px;
`

const AddPersonText = styled.span`
    font-family: ${font.CircularTTMedium};
    font-size: 12px;
    opacity: 40%;
    position: relative;
    bottom: 10px;
`


export default function InvitePane() {

    const { addNotification } = useContext(Context);

    const [participants, setParticipants] = useState([
        {
            name: "",
            email: "",
            error: ""
        }
    ])

    const handleNameChange = (e) => {
        let temp = [...participants];
        temp[parseInt(e.target.name)].name = e.target.value;
        setParticipants(temp);
    };

    const handleEmailChange = (e) => {
        let temp = [...participants];
        temp[parseInt(e.target.name)].email = e.target.value;
        setParticipants(temp);
    };

    const addParticipant = () => {
        let newParticipants = [...participants, {
            name: "",
            email: "",
            error: ""
        }];
        setParticipants(newParticipants);
        setTimeout(() => {
            document.getElementById(newParticipants.length - 1).focus();
        }, 10);
    };

    const removeParticipant = () => {
        let newParticpants = participants.slice(0, participants.length - 1);
        setParticipants(newParticpants);
        setTimeout(() => {
            document.getElementById(newParticpants.length - 1).focus();
        }, 10);
    };

    const onSubmit = () => {
        // const emailError = emailValidator(partipant.error);
        // if (emailError) {
        //     setEmail((prev) => ({ ...prev, error: emailError }));
        //     return;
        // } else {
        //     // email = user@framewrk.com, password = password
        //     history.push('/');
        // }
        // console.log(email);
    };

    useEffect(() => {
        const notificationData = {
            body: `Invited members will receive an email request to enter their 
            availability. Once a time slot is confirmed, a pre-workshop
            survey and reference materials will be sent.`,
            title: <img src={LightningCloudSVG} />,
            dark: true
        }
        addNotification(notificationData)
    }, [])

    return (
        <>
            <InviteTitle>Invite</InviteTitle>
            <InviteSubtitle>Select which team members or clients to invite to your branding workshop</InviteSubtitle>
            {participants.map((participant, i) => {
                return (
                    <>
                        <TextInput

                            startAdornment={<InputAdornment><UserIcon /></InputAdornment>}
                            value={participant.name}
                            onChange={handleNameChange}
                            name={i}
                            id={i}
                        />
                        <TextInput
                            startAdornment={<InputAdornment><MailIcon /></InputAdornment>}
                            endAdornment={<InputAdornment position="end"><ErrorComponent error={participant.error} /></InputAdornment>}
                            value={participant.email}
                            onChange={handleEmailChange}
                            error={participant.error !== ''}
                            name={i}
                        />
                        {i === participants.length - 1 && participants.length > 1 ? (
                            <IconButton
                                onClick={removeParticipant}
                                style={{ position: 'relative', bottom: '5px' }}
                            >
                                <RemoveCircleOutlineIcon style={{ color: theme.primary }} />
                            </IconButton>
                        ) : null}
                    </>
                )
            }
            )}
            <br />
            <div style={{ textAlign: 'center' }}>
                <IconButton onClick={addParticipant}>
                    <AddCircleOutlineIcon style={{ color: theme.primary }} />
                </IconButton>
                <br />
                <AddPersonText>Add Person</AddPersonText>
            </div>
        </>
    )
}
