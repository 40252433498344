import React from 'react';
import styled from 'styled-components/macro';
import theme from '../../../core/theme';
import * as font from '../../../core/font';
import { ReactComponent as triangleSvg } from '../../../assets/image/messageTriangle.svg';

const OutgoingMsg = styled.div`
  width: 380px;
  justify-content: center;
  margin: 5px 0px ${(props) => (props.displayTriangle ? `15px` : `5px`)} 10px;
  padding: 10px;
  background-color: ${theme.background};
  color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  font-family: ${font.WorkSansSemiBold};
  font-weight: 14px;
  position: relative;
`;

const Triangle = styled(triangleSvg)`
  path {
    fill: ${theme.background};
  }
  position: absolute;
  right: 17px;
  bottom: -13px;
  z-index: 1;
`;

export default function IncomingMessage({ children, displayTriangle }) {
  if (Array.isArray(children)) {
    return children.map((child, i) => (
      <OutgoingMsg>
        {child}
        {displayTriangle && i === children.length - 1 ? <Triangle /> : null}
      </OutgoingMsg>
    ));
  }
  return (
    <OutgoingMsg>
      {children}
      {displayTriangle ? <Triangle /> : null}
    </OutgoingMsg>
  );
}
