import { Alert, Box, Checkbox, Container, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import { Button as AntdButton } from 'antd';
import axios from "axios";
import { useEffect, useState } from 'react';
import { redirect } from "react-router-dom";
import { getStripe } from "../../core/utils";
import { Title } from "../../GlobalComponents";
import { useAuth } from "../../providers/AuthContext";
import { currencyUSFormatter } from "../../utils";
import * as events from "../../utils/events";
import { EasterEgg } from "../Auth/Components";
import TopNav from "../Office/Components/TopNav";
import { ButtonText, CustomButton, Subtitle } from './styles';


const { REACT_APP_BACKEND_API_URL: baseUrl } = process.env;

const Account = () => {
  const { currentUser, stripeSubscription } = useAuth()
  const [token, setToken] = useState('')
  const [product, setProduct] = useState()
  const [priceIsLoading, setPriceIsLoading] = useState(false);
  const [currentPrice, setCurrentPrice] = useState();

  useEffect(() => {
    window.gtag('event', events.ACCOUNT_SCREEN_VIEW, {
      userId: currentUser.uid
    });
  }, [currentUser.uid])

  useEffect(() => {
    const fetchData = async () => {
      const token = currentUser && (await currentUser.getIdToken());
      setToken(token)

      const url = `${baseUrl}/stripe/products`;
      const response = await axios.get(url);
      const data = response.data[0]
      setProduct(data)
      setCurrentPrice(data.defaultPrice)
    }
    fetchData()
  }, [currentUser])

  const pay = async (priceId) => {
    const url = `${baseUrl}/stripe/create-checkout-session`;
    const response = await axios.post(url, { price: { id: priceId }, quantity: 1 }, { headers: { Authorization: `Bearer ${token}` } });
    if (response.data.sessionId) {
      window.gtag('event', events.ACCOUNT_REDIRECT_TO_STRIPE, {
        userId: currentUser.uid,
        priceId
      });
      const stripe = await getStripe();
      await stripe.redirectToCheckout({ sessionId: response.data.sessionId });
    }
  }

  const handleCheckout = async (priceId) => {
    window.gtag('event', events.ACCOUNT_CHECKOUT_PRESSED, {
      userId: currentUser.uid,
      priceId
    });
    if (!priceId) {
      return;
    }
    if (stripeSubscription) {
      return redirect("/account")
    }
    setPriceIsLoading(true);

    try {
      await pay(priceId)
    } catch (error) {
      return alert(error.message);
    } finally {
      setPriceIsLoading(false);
    }
  };

  const redirectToCustomerPortal = async () => {
    setPriceIsLoading(true)
    window.gtag('event', events.ACCOUNT_REDIRECT_TO_STRIPE_MANAGE_PORTAL, {
      userId: currentUser.uid
    });
    const url = `${baseUrl}/stripe/create-portal-link`;
    const response = await axios.post(url, {}, { headers: { Authorization: `Bearer ${token}` } });
    window.open(response.data.url, '_self');
    setPriceIsLoading(false)
  };

  if (stripeSubscription) {
    return (
      <div style={{ backgroundColor: '#E1F2E9', height: "100vh", display: 'flex' }}>
        <TopNav />
        <Container>
          <Stack alignItems='center' justifyContent='center' marginTop={30}>
            <Typography fontSize={16}>Manage your subscription on Stripe</Typography>
            <AntdButton
              type="primary"
              size="large"
              style={{
                marginTop: '10px',
                height: 'auto'
              }}
              disabled={priceIsLoading}
              loading={priceIsLoading}
              onClick={redirectToCustomerPortal}
            >
              <span style={{
                color: '#4a4a4a',
                fontSize: '20px',
                fontWeight: '700',
                padding: '6px 10px'
              }}>
                Open customer portal
              </span>
            </AntdButton>
            <List sx={{ width: '100%', maxWidth: 360 }}>
              {stripeSubscription.cancelAtPeriodEnd &&
                <ListItem
                  disableGutters
                >
                  <ListItemText primary={
                    <Alert severity="warning">After your free trial ends on <strong>{new Date(stripeSubscription.cancelAt).toLocaleDateString()}</strong>, this plan will no longer be available.</Alert>
                  } />
                </ListItem>
              }
              <ListItem
                disableGutters
                secondaryAction={
                  <ListItemText primary={currencyUSFormatter.format(stripeSubscription.price.unitAmount / 100)} />
                }
              >
                <ListItemText primary='Price' />
              </ListItem>
              <ListItem
                disableGutters
                secondaryAction={
                  <ListItemText primary={stripeSubscription.price.interval} />
                }
              >
                <ListItemText primary='Changed every' />
              </ListItem>
              <ListItem
                disableGutters
                secondaryAction={
                  <ListItemText primary={stripeSubscription.status === 'trialing' ? 'Trial' : stripeSubscription.status} />
                }
              >
                <ListItemText primary='Status' />
              </ListItem>
              {stripeSubscription.status === 'trialing' && (
                <ListItem
                  disableGutters
                  secondaryAction={
                    <ListItemText primary={new Date(stripeSubscription.trialEnd).toLocaleDateString()} />
                  }
                >
                  <ListItemText primary='Trial ends on' />
                </ListItem>
              )}
              <ListItem
                disableGutters
                secondaryAction={
                  <ListItemText primary={new Date(stripeSubscription.currentPeriodStart).toLocaleDateString()} />
                }
              >
                <ListItemText primary='Current period started on' />
              </ListItem>
              <ListItem
                disableGutters
                secondaryAction={
                  <ListItemText primary={new Date(stripeSubscription.currentPeriodEnd).toLocaleDateString()} />
                }
              >
                <ListItemText primary='Bill will be charged on' />
              </ListItem>
            </List>
          </Stack>
        </Container>
      </div>
    )
  }

  return (
    <div style={{ backgroundColor: '#E1F2E9', height: "100vh", display: 'flex' }}>
      <TopNav />
      <Container>
        <Stack direction='row' spacing={2} style={{ paddingTop: 70 }}>
          <EasterEgg />
          <Title>It's time to subscribe!</Title>
        </Stack>
        <Stack alignItems="center" style={{ marginTop: 20, marginBottom: 40 }}>
          <Subtitle>Choose an option below.</Subtitle>
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6} justifyContent='center'>
          {product?.prices.map(price => {
            const priceString = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: price.currency,
              minimumFractionDigits: 0,
            }).format(price.unitAmount / 100);
            return <Stack key={price.id} direction='column' alignItems="center" >
              <Box
                key={price.id}
                sx={{
                  cursor: 'pointer',
                  borderRadius: '10px',
                  width: 300,
                  height: 300,
                  backgroundColor: '#FFFFFF',
                  opacity: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Subtitle style={{ marginTop: 20 }}>{price.metadata.title}</Subtitle>
                <Stack direction='column' alignItems="center" justifyContent='center' style={{
                  padding: 10,
                  margin: 10,
                  backgroundColor: '#4EBA9A',
                  borderRadius: "50%",
                  width: 130,
                  height: 130,
                }} >
                  <div style={{ color: `#FFFFFF`, fontFamily: 'WorkSans-Medium', fontSize: 20, fontWeight: 'bold' }}>{priceString}</div>
                  <div style={{ margin: 5, fontWeight: 'bold' }}>Billed {price.interval === 'month' ? 'Monthly' : 'Yearly'}</div>
                </Stack>
                <div style={{ color: `#000000`, fontSize: 13, fontWeight: 'bold', textAlign: 'center', margin: 10 }}>{price.description}</div>
                <Checkbox checked={currentPrice === price.id} onChange={() => {
                  window.gtag('event', events.ACCOUNT_PICK_PRICE, {
                    userId: currentUser.uid,
                    priceId: price.id
                  });
                  setCurrentPrice(price.id)
                }} />
              </Box>

            </Stack>
          })}
        </Stack>
        <Stack alignItems="center" style={{ margin: 20 }}>
          <CustomButton
            disabled={priceIsLoading}
            onClick={() => handleCheckout(currentPrice)}
          >
            <ButtonText>
              {currentPrice === stripeSubscription?.priceId
                ? 'Manage'
                : 'Subscribe'
              }
            </ButtonText>
          </CustomButton>
        </Stack>
      </Container >
    </div >
  );
};

export default Account;
