import React, { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components/macro';
import * as font from '../../../core/font';
import theme from '../../../core/theme';
import IncomingMessage from './IncomingMessage';
import OutgoingMessage from './OutgoingMessage';
import VideoMessage from './VideoMessage';
import SingleChoice from './SingleChoice';
import AudioPlayer from './AudioPlayer';
import MultipleInput from './MultipleInput';
import { Context } from '../../../providers/Context';
import { auth } from '../../../firebase/firebase';

const Typing = styled.div`
  border-radius: 10px;
  color: ${theme.primary};
  width: 50%;
  padding: 5px;
  ::after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '...';
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 500px;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  /* Hide scrollbar for Chrome, Safari and Opera */
  & ::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const SenderLabel = styled.span`
  font-family: ${font.WorkSansSemiBold};
  font-size: 18px;
  color: ${theme.primary};
  margin-left: 15px;
`;

const TimeStamp = styled.span`
  font-family: ${font.WorkSansMedium};
  font-size: 14px;
  color: black;
  opacity: 16%;
`;

export default function MessagesContainer({
  messages,
  options,
  _onSendMessage,
  id,
  maxLength,
  isTyping,
  users,
}) {
  const {
    setHomeBaseTextInputToggle,
    setHomeBaseSendToggle,
    setHomeBaseAudioInputToggle,
  } = useContext(Context);

  const scrollRef = useRef(null);

  useEffect(() => {
    // console.log('Messages: ', messages);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    let lastMessageType = messages[messages.length - 1]?.data.MessageType;
    // console.log(lastMessageType);
    if (
      (options && options.length) ||
      lastMessageType === 'SingleChoice' ||
      lastMessageType === 'List'
    ) {
      setHomeBaseSendToggle(false);
      setHomeBaseAudioInputToggle(false);
      setHomeBaseTextInputToggle(false);
    } else {
      setHomeBaseSendToggle(false);
      setHomeBaseAudioInputToggle(true);
      setHomeBaseTextInputToggle(true);
    }
  }, [messages, isTyping]);

  return (
    <Wrapper>
      {messages.map((message, i) => {
        let firstOfGroup = message.data.Sender !== messages[i - 1]?.data.Sender;
        let lastOfGroup = message.data.Sender !== messages[i + 1]?.data.Sender;
        let messageSender = '';
        messageSender =
          message.data.Sender === 'Framewrk'
            ? 'Framewrk'
            : users.filter((user) => user.id === message.data.Sender)[0]?.data
                .Username || '';

        let messageChildren;

        let sentTime = new Date(message.data.SentDate);
        let sentHour = sentTime.getHours();
        if (sentHour > 12) {
          sentHour = sentHour - 12;
        }
        let sentMinutes = sentTime.getMinutes();
        if (sentMinutes < 10) sentMinutes = '0' + sentMinutes;
        sentTime = `${sentHour}:${sentMinutes}`;

        switch (message.data.MessageType || message.data.Type) {
          case 'Freewrite' ||
            JSON.stringify(message.data.MessageType) ===
              '["Freewrite", "Audio"]':
            messageChildren = message.data.Content;
            break;
          case 'Video':
            messageChildren = <VideoMessage URL={message.data.VideoURL} />;
            break;
          case 'Audio':
            messageChildren = (
              <AudioPlayer
                uri={message.data.Content}
                id={message.key || 'player'}
              />
            );
            break;
          case 'List':
            messageChildren =
              message.data.Sender === 'Framewrk'
                ? message.data.Content
                : JSON.parse(message.data.Content);
            break;
          case 'SingleChoice':
            messageChildren =
              message.data.Sender === 'Framewrk' ? (
                message.data.Content
              ) : (
                <SingleChoice
                  options={[message.data.Content]}
                  _onSendMessage={_onSendMessage}
                  id={id}
                />
              );
            break;
          default:
            messageChildren = message.data.Content;
        }

        return message.data.Sender !== auth.currentUser.uid ? (
          <div key={'message' + i}>
            {firstOfGroup ? (
              <SenderLabel>
                {messageSender} <TimeStamp>{sentTime}</TimeStamp>
              </SenderLabel>
            ) : null}
            <IncomingMessage>{messageChildren}</IncomingMessage>
            {options && options.length && i === messages.length - 1 ? (
              <OutgoingMessage displayTriangle={lastOfGroup}>
                <SingleChoice
                  options={options}
                  _onSendMessage={_onSendMessage}
                  id={id}
                />
              </OutgoingMessage>
            ) : null}
            {message.data.MessageType === 'List' &&
            i === messages.length - 1 ? (
              <OutgoingMessage displayTriangle={true}>
                <MultipleInput maxLength={maxLength} />
              </OutgoingMessage>
            ) : null}
          </div>
        ) : (
          <div key={'message' + i}>
            {firstOfGroup ? (
              <SenderLabel>
                <TimeStamp>{sentTime}</TimeStamp>
              </SenderLabel>
            ) : null}
            <OutgoingMessage displayTriangle={lastOfGroup}>
              {messageChildren}
            </OutgoingMessage>
          </div>
        );
      })}
      {isTyping ? (
        <IncomingMessage>
          <Typing>Framewrk is typing</Typing>
        </IncomingMessage>
      ) : null}
      <div ref={scrollRef} />
    </Wrapper>
  );
}
