let Mastermind, EasterEgg, Partner;

Mastermind = {
  BrandingMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FBranding%20Mastermind.mp4?alt=media&token=f6638c95-d787-4fbd-9fd5-14ea8381b3ab',

  CompetitorMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FCompetitor%20Mastermind.mp4?alt=media&token=b0f5f725-df4d-4b3b-90ef-df1e2f701cab',

  FinanceMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FFinance%20Mastermind.mp4?alt=media&token=2c38710b-729f-4b60-98de-0d59bf7b5a4f',

  LegalMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FLegal%20Mastermind.mp4?alt=media&token=d5461bd5-549d-4042-8b35-295dc6971631',

  MarketingMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FMarketing%20Mastermind.mp4?alt=media&token=04dafa0b-ba31-4fb2-83c7-adb2622d3edf',

  OpsMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FOps%20Mastermind.mp4?alt=media&token=c442ca6e-b851-4a5a-ad06-71d3a7f3bdc1',

  RiskMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FRisk%20Mastermind.mp4?alt=media&token=87080d5b-c8ae-45f2-9ddb-9dddb7e202bd',

  SelfMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FSelf%20Mastermind.mp4?alt=media&token=e8c83c0d-e938-40ad-a82e-b1355444fb83',

  TeamMastermind:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FMastermind%2FTeam%20Mastermind.mp4?alt=media&token=a408fa56-bc74-431a-a305-7680885a9726',
};

EasterEgg = {
  EveningVideo:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FEasterEgg%2FEasterEgg_Evening_Video.mp4?alt=media&token=cf643104-434c-484b-8bd6-117fb0afb014',
  MorningAudio:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FEasterEgg%2FEasterEgg_Morning_Audio.m4a?alt=media&token=ee5610cc-3648-4d8d-b287-d7ab9a1afeab',
};

Partner = {
  A_Video:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FPartner%2FClient%20A%20-%20Partner%20Mode%20Tutorial.mp4?alt=media&token=8cbdbfec-75bc-4038-8403-f97f3d755ab9',
  B_Video:
    'https://firebasestorage.googleapis.com/v0/b/framewrk-prod.appspot.com/o/Materials%2FPartner%2FClient%20B%20-%20Partner%20Mode%20Tutorial.mp4?alt=media&token=fe8ccf08-d9df-406d-8cbd-b2cb286b5285',
};

export { Mastermind, EasterEgg, Partner };
