import { Checkbox, FormControlLabel } from "@mui/material";
import { withStyles } from '@mui/styles';
import React from "react";

import theme from "../../../core/theme";

const GreenCheckbox = withStyles({
  root: {
    backgroundColor: theme.background,
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: '-10px',
    color: theme.disabled,
    "&$checked": {
      color: theme.secondary,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CheckBox(props) {
  return (
    <FormControlLabel
      control={
        <GreenCheckbox
          checked={props.checked}
          onChange={props.onChange}
          name="checkedG"
        />
      }
      style={{ marginTop: '30px', alignItems: 'start' }}
      label={props.label}
    />
  );
}
export default CheckBox;
