import LeftSideDetailComponent from '../LeftSideDetailComponent';
import RightSideLoginComponent from './RightSideLoginComponent';

import styles from './index.module.scss';

const NewLogin = () => {
  return (
    <div className={styles.fwrkLogin}>
      <LeftSideDetailComponent />
      <RightSideLoginComponent />
    </div>
  );
};

export default NewLogin;
