import { Button, Input } from "antd";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import { useState } from "react";

const RightSideBarInviteComponent = () => {
  const [inviteUserName, setInviteUserName] = useState("");
  const [inviteUserEmail, setInviteUserEmail] = useState("");

  return (
    <div className={styles.accountSetupIndexComponent}>
      <div className={styles.accountSetupIndexTitle}>Invite</div>
      <div>
        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Know other Founders?</div>
          <div className={styles.accountSetupIndexDetailText}>
            Know any other Founders who would love to try Framewrk? Write down their name and email address below, and we'll send them more info!
          </div>

          <div>
            <div className={styles.inviteInputContainer}>
              <div className={styles.inviteInputTitle}>First Name</div>
              <div>
                <div className={styles.signupInput}>
                  <Input size="large" placeholder="Enter First Name" prefix={<UserOutlined />} value={inviteUserName} onChange={(e:any) => setInviteUserName(e?.target?.value)} />
                </div>
              </div>
            </div>

            <div className={styles.inviteInputContainer}>
              <div className={styles.inviteInputTitle}>Email</div>
              <div>
                <div className={styles.loginInput}>
                  <Input type="email" size="large" placeholder="Enter Email" prefix={<MailOutlined />} value={inviteUserEmail} onChange={(e:any) => setInviteUserEmail(e?.target?.value)} />
                </div>
              </div>
            </div>

            <div className={styles.inviteButtonContainer}>
              <Button className={styles.inviteButton} type="primary" size="large">
                <span className={styles.accoutSetupNextSectionButtonText}>Send Invite</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBarInviteComponent;
