import React from 'react';
import theme from '../../../../core/theme';
import * as font from '../../../../core/font';
import styled from 'styled-components/macro';

const ButtonContainer = styled.div`
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ToggleJoinButton = styled.button`
  align-items: center;
  background-color: ${(props) => props.selected ? theme.secondary : `inherit`};
  outline: none;
  border-radius: 15px;
  box-shadow: ${(props) =>
    `0 0 ${props.selected ? '10px' : 0} ${theme.secondary}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 15px;
  width: 230px;
  animation: fadeIn ease .5s;
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
`;

const ButtonText = styled.span`
  color: ${(props) => props.selected ? `white` : `black`};
  opacity: ${(props) => props.selected ? `100%` : `49%`};
  font-family: ${font.WorkSansSemiBold};
  font-size: 20px;
  margin-right: 10px;
`;

const Description = styled.p`
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
  color: grey;
`

const Title = styled.h4`
  font-family: ${font.WorkSansSemiBold};
  font-size: 30px;
  text-align: center;
`

const Step3 = ({ flag, setFlag }) => {
  return (
    <div >
        <Title>Join Mastermind</Title>
        <Description>
          Ready to join? We'll create a group with people that we think you'd
          connect well with.
        </Description>
      <ButtonContainer>
        <ToggleJoinButton
          onClick={() => setFlag(true)}
          selected={flag}
        >
          <ButtonText
            selected={flag}
          >
            Let's do this.
          </ButtonText>
        </ToggleJoinButton>
        <ToggleJoinButton
          onClick={() => setFlag(false)}
          selected={!flag}
        >
          <ButtonText
            selected={!flag}
          >
            Maybe later
          </ButtonText>
        </ToggleJoinButton>
      </ButtonContainer>
    </div>
  );
};

export default Step3;
