import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import videoURLS from '../../../assets/videoURLS.json';
import theme from '../../../core/theme';
import * as GlobalSC from '../../../GlobalComponents';
import { useMyContext } from '../../../providers/Context';
import * as RegionalSC from '../Components';
import * as SC from './styles';

const arrow = require('../../../assets/image/arrow_next.png');

function OfficeReady() {
  const navigate = useNavigate();

  let {
    backgroundVideo,
    setBackgroundVideo,
    backgroundStill,
    setBackgroundStill,
    backgroundAudio,
    setBackgroundAudio,
  } = useMyContext();

  const [loaded, setLoaded] = useState(false);

  function goBack() {
    navigate('/choose-your-office');
  }

  function start() {
    navigate('/office');
  }

  // Preload Office Video Background into browser cache
  async function cacheOfficeBackground(imageSrc, videoSrc, audioSrc) {
    try {
      const videoPromise = new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = videoSrc;
        video.poster = imageSrc;
        video.oncanplay = () => {
          resolve(`Video and Still Loaded: ${videoSrc}, ${imageSrc}`);
        };
        video.onerror = reject;
      });

      const audioPromise = new Promise((resolve, reject) => {
        const audio = new Audio(audioSrc);
        audio.oncanplay = () => {
          resolve('Audio Loaded:' + audioSrc);
        };
        audio.onerror = reject;
      });

      await Promise.all([videoPromise, audioPromise]);
    } catch (err) {
      console.error('Error Caching Resources:', err);
    } finally {
      setLoaded(true);
    }
  }

  useEffect(() => {
    // If links aren't in Context, pull from LocalStorage, if not there, fall back to calmForest
    if (!backgroundVideo || !backgroundStill || !backgroundAudio) {
      backgroundVideo = localStorage.getItem('backgroundVideo');
      backgroundStill = localStorage.getItem('backgroundStill');
      backgroundAudio = localStorage.getItem('backgroundAudio');
      backgroundVideo
        ? setBackgroundVideo(backgroundVideo)
        : setBackgroundVideo(videoURLS.officeBackgrounds[0].video);
      backgroundStill
        ? setBackgroundStill(backgroundStill)
        : setBackgroundStill(videoURLS.officeBackgrounds[0].still);
      backgroundAudio
        ? setBackgroundAudio(backgroundAudio)
        : setBackgroundAudio(videoURLS.officeBackgrounds[0].audio);
    }
    // Preload video and audio for office
    cacheOfficeBackground(backgroundStill, backgroundVideo, backgroundAudio);
  }, []);

  return (
    <>
      <RegionalSC.Background />
      <RegionalSC.Container style={{ justifyContent: 'flex-start' }}>
        <SC.LocalSubContainer>
          <GlobalSC.H1>
            Your Office is {loaded ? 'Ready!' : 'Loading...'}
          </GlobalSC.H1>
          <GlobalSC.H2 style={{ textAlign: 'center' }}>
            Ready to work?
          </GlobalSC.H2>
        </SC.LocalSubContainer>
        <div
          style={{
            marginTop: '20vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loaded ? (
            <>
              <SC.StartBackButton
                active
                bgColor={theme.secondary}
                onClick={start}
              >
                <SC.StartBackButtonText active>
                  Let's Go!
                </SC.StartBackButtonText>
                <img src={arrow} alt="next btn" width={30} />
              </SC.StartBackButton>
              <SC.StartBackButton onClick={goBack}>
                <SC.StartBackButtonText>Back</SC.StartBackButtonText>
              </SC.StartBackButton>
            </>
          ) : (
            <GlobalSC.SpinnerIcon style={{ margin: '0 auto' }} />
          )}
        </div>
      </RegionalSC.Container>
    </>
  );
}

export default OfficeReady;
