import React from 'react';
import styled from 'styled-components/macro';
import LeftContainer from '../LeftContainer';
import RightContainer from './RightContainer';
import videoURLS from '../../../assets/videoURLS.json';

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`;

const Video = styled.video`
  position: absolute;
  width: 40%;
`;

const LearnMore = () => (
  <Container>
    <LeftContainer>
      <Video autoPlay loop muted>
        <source src={videoURLS.learnMore} type="video/mp4" />
      </Video>
    </LeftContainer>
    <RightContainer />
  </Container>
);

export default LearnMore;
