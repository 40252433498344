// This file contains all text styles for this website except for local overrides

import styled from 'styled-components/macro';
import * as font from '../core/font';
import theme from '../core/theme';

export const Text = styled.span`
  color: black;
  font-family: ${font.WorkSansMedium};
  font-size: 12px;
  line-height: 14px;
`;

export const H1 = styled.h1`
  color: black;  
  font-family: ${font.WorkSansSemiBold};
  font-size: 30px;
  line-height: 35px;
  margin: 10px 0;
  text-align: center;
`;

export const H2 = styled.h2`
  color: black;
  font-weight: normal;
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
  line-height: 18px;
  opacity: 35%;
`;

export const Title = styled(Text)`
  font-family: ${font.WorkSansSemiBold};
  font-size: 35px;
  line-height: 42px;
`;

export const Description = styled(Text)`
  color: ${theme.disabled};
  font-family: ${font.CircularTTMedium};
  font-size: 16px;
`;

export const NextButtonText = styled(Text)`
  color: ${theme.disabled};
  cursor: pointer;
  font-family: ${font.WorkSansMedium};
  font-size: 16px;
  line-height: 19px;
`;

export const Label = styled(Text)`
  font-family: ${font.WorkSansMedium};
  font-size: 18px;
  font-weight: 200;
  line-height: 21px;
  margin: 0 0 5px 5px;
`;

export const Question = styled(Text)`
  color: ${theme.disabled};
  font-family: ${font.CircularTTMedium};
`;

export const SignUpIn = styled(Question)`
  color: ${theme.secondary};
  cursor: pointer;
  margin-left: 15px;
`;

export const Highlight = styled.span`
  color: ${theme.primary};
`;

