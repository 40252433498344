import styled from 'styled-components/macro';
import gmIcon from '../../../assets/image/gmIcon.svg';
import { useMyContext } from '../../../providers/Context';

const FoldoutGroup = styled.div`
    height:60px;
    padding-left:30px;
    padding-top: 10px;
    padding-right:20px;
    display: flex;
    justify-content: space-between;   
    align-items:center; 
    padding-bottom:15px;
    &:hover{
        background-color:#FAF2E1;
        cursor:pointer;
        transition-delay:.08s;
    }
`;

const Icons = styled.img`
margin-right: 10px;
height: auto;
width: 30px;
`;
const Title = styled.div`
    display:flex;
    align-items:center;
`;

const TitleText = styled.div`
    padding-top: 8px;
    margin-top: 6px;
`;

const mastermindDetail = {
    name: "General",
    description: "Join General Mastermind Group"
}

function MastermindGroups(props) {

    const { setMastermindState } = useMyContext();

    const _onClicked = (event) => {
        setMastermindState({ groupName: mastermindDetail?.name, firstVisit: true })
    };

    return (
        <div >
            <FoldoutGroup onClick={() => { }}>
                <Title onClick={_onClicked}>
                    <Icons src={gmIcon} />
                    <TitleText>
                        <h3>Explore Mastermind Group</h3>
                        <p>Four brains are better than one.</p>
                    </TitleText>
                </Title>
            </FoldoutGroup>
        </div>
    )
}

export default MastermindGroups
