import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const Context = createContext();

export function useMyContext() {
  return useContext(Context)
}

export default function ContextProvider({ children }) {
  const navigate = useNavigate();

  const [mastermindState, setMastermindState] = useState({ display: false })
  const [backgroundVideo, setBackgroundVideo] = useState();
  const [backgroundStill, setBackgroundStill] = useState();
  const [backgroundAudio, setBackgroundAudio] = useState();
  const [volume, setVolume] = useState(100);
  const [homeBaseToggle, setHomeBaseToggle] = useState(false);
  const [expandHomeBase, setExpandHomeBase] = useState(true);
  const [messengerState, setMessengerState] = useState({
    display: false,
    id: null,
  });
  const [homeBaseTextInputToggle, setHomeBaseTextInputToggle] = useState(true);
  const [homeBaseSendToggle, setHomeBaseSendToggle] = useState(true);
  const [homeBaseAudioInputToggle, setHomeBaseAudioInputToggle] = useState(
    true
  );
  const [homeBaseMessage, setHomeBaseMessage] = useState({
    contents: '',
    type: null,
  });
  const [notificationArray, setNotificationArray] = useState([])
  const [notificationOpen, setNotificationOpen] = useState(false)
  const [notification, setNotification] = useState({});

  function addNotification(notification) {
    setNotificationArray([...notificationArray, notification])
    setNotification(notification)
    setNotificationOpen(true)
  }

  function removeNotification(index) {
    let temp = notificationArray
    temp.splice(index, 1)
    setNotificationArray(temp)
  }

  function openNotification() {
    setNotificationOpen(true)
  }

  function closeNotification() {
    setNotificationOpen(false)
  }

  const [roadmapPath, setRoadmapPath] = useState("/office/roadmap")
  function setRoadmapPathPushHistory(path) {
    navigate(path)
    setRoadmapPath(path)
  }

  return (
    <Context.Provider
      value={{
        backgroundVideo,
        setBackgroundVideo,
        backgroundStill,
        setBackgroundStill,
        volume,
        setVolume,
        homeBaseToggle,
        setHomeBaseToggle,
        expandHomeBase,
        setExpandHomeBase,
        messengerState,
        setMessengerState,
        homeBaseTextInputToggle,
        setHomeBaseTextInputToggle,
        homeBaseSendToggle,
        setHomeBaseSendToggle,
        homeBaseAudioInputToggle,
        setHomeBaseAudioInputToggle,
        homeBaseMessage,
        setHomeBaseMessage,
        notification,
        setNotification,
        openNotification,
        closeNotification,
        backgroundAudio,
        setBackgroundAudio,
        notificationOpen,
        setNotificationOpen,
        addNotification,
        removeNotification,
        notificationArray,
        setNotificationArray,
        roadmapPath,
        setRoadmapPathPushHistory,
        mastermindState,
        setMastermindState
      }}
    >
      {children}
    </Context.Provider>
  );
}
