import { Button } from "antd";
import { apiMailingAddress } from "api/account-setup/mailingAddress";
import { IFormStep } from "interface";
import { AppContext } from "providers/app-context";
import { useAuth } from "providers/AuthContext";
import { useContext, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { toast } from "utils/toast";
import styles from "./../index.module.scss";

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

interface IProps {
  currentForm: IFormStep;
  navigateToFormStep: (currentForm: IFormStep, direction: "next" | "prev") => void;
  updateCompletePercentage: React.Dispatch<React.SetStateAction<number>>;
}

const MailingAddressForm = ({ currentForm, navigateToFormStep, updateCompletePercentage }: IProps) => {
  const { mailingAddress, setMailingAddress } = useContext(AppContext);
  const { currentUser, userAuthToken } = useAuth();
  const [mailingAddressUpdateLoading, setMailingAddressUpdateLoading] = useState(false);

  useEffect(() => {
    updateCompletePercentage(80);
  }, [updateCompletePercentage]);

  const mailingAddressUpdated = (updatedValue: any) => {
    setMailingAddress(updatedValue?.formatted_address);
    console.log(updatedValue);
  };

  const updateMailingAddress = async () => {
    try {
      setMailingAddressUpdateLoading(true);
      const payload = {
        uid: currentUser?.uid,
        mail: mailingAddress,
      };
      await apiMailingAddress(userAuthToken!, payload);
      navigateToFormStep(currentForm, "next");
    } catch (e: any) {
      console.log("Error : ", e);
      toast.error(e?.message ?? "Something Went Wrong!");
    } finally {
      setMailingAddressUpdateLoading(false);
    }
  };

  return (
    <>
      <div className={styles.accountSetupMainTitleContainer}>
        <div className={styles.mainTitleNumber}>{currentForm?.index}</div>
        <div className={styles.mainTitleName}>{currentForm?.title}</div>
      </div>

      <div className={styles.accountSetupMainFormContainer}>
        <div className={styles.formTypeText}>Free Write</div>
        <div className={styles.formQuestionText}>What is your physical mailing address?</div>
        <div className={styles.formQuestionDetail}>Because you signed up for Framewrk, we have a special gift we'd like to send you.</div>
        <div className={`${styles.formInputContainer} ${styles.formTextInput}`}>
          <Autocomplete
            className={`${styles.companyMailingAddressInputBox}`}
            placeholder={mailingAddress ?? "Write your response here"}
            apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
            onPlaceSelected={mailingAddressUpdated}
            autoFocus={true}
          />
        </div>

        <div className={styles.nextSectionButtonContainer}>
          <Button
            type="primary"
            size="large"
            className={styles.accoutSetupNextSectionButton}
            onClick={updateMailingAddress}
            loading={mailingAddressUpdateLoading}
            disabled={mailingAddressUpdateLoading || !mailingAddress}
          >
            <span className={styles.accoutSetupNextSectionButtonText}>Next Section</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default MailingAddressForm;
