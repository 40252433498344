import styles from "./index.module.scss";

const RightSideBarIndustryExampleComponent = () => {
  return (
    <div className={styles.accountSetupIndexComponent}>
      <div className={styles.accountSetupIndexTitle}>Example</div>
      <div style={{ width: "100%" }}>
        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Ideation</div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Validation</div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Operational</div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Growth</div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Establishing</div>
        </div>

        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Exiting</div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBarIndustryExampleComponent;
