import styles from "./index.module.scss";

const RightSideBarMailingExampleComponent = () => {
  return (
    <div className={styles.accountSetupIndexComponent}>
      <div className={styles.accountSetupIndexTitle}>Example</div>
      <div>
        <div className={styles.accountSetupIndexDetail}>
          <div className={styles.accountSetupIndexDetailTitle}>Physical Mailing Address</div>
          <div className={styles.accountSetupIndexDetailText}>
            Arnold Patacki
            <br />
            1234 Founder Avenue.
            <br />
            Seattle, WS
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBarMailingExampleComponent;
