import { IFormSteps } from "interface";

export const AccountSetupForms: IFormSteps = {
  STEP1: {
    prevStep: undefined,
    index: "01",
    nextStep: "02",
    title: "Company Stage"
  },
  STEP2: {
    prevStep: "01",
    index: "02",
    nextStep: "03",
    title: "Company Industry",
  },
  STEP3: {
    prevStep: "02",
    index: "03",
    nextStep: "04",
    title: "Founder Gender",
  },
  STEP4: {
    prevStep: "03",
    index: "04",
    nextStep: "05",
    title: "Founder Race",
  },
  STEP5: {
    prevStep: "04",
    index: "05",
    nextStep: "06",
    title: "Mailing Address",
  },
  STEP6: {
    prevStep: "05",
    index: "06",
    nextStep: undefined,
    title: "Congratulations!",
  },
};
